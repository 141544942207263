import { createSlice } from "@reduxjs/toolkit";
import { CommunityPageState } from "../../types/screen";

const initialState: CommunityPageState = {
  targetBoArticles: [],
  comments: null,
  createReply: null,
};

const communityPageSlice = createSlice({
  name: "communityPage",
  initialState,
  reducers: {
    setTargetBoArticles: (state, action) => {
      state.targetBoArticles = action.payload;
    },
    setChosenComment: (state, action) => {
      state.comments = action.payload;
    },
    setChosenCreateReply: (state, action) => {
      state.createReply = action.payload;
    },
  },
});

export const { setTargetBoArticles, setChosenComment, setChosenCreateReply } =
  communityPageSlice.actions;

const CommunityPageReducer = communityPageSlice.reducer;
export default CommunityPageReducer;
