import { Box, Stack } from "@mui/joy";
import { Container, PaginationItem } from "@mui/material";
import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import LaunchIcon from "@mui/icons-material/Launch";
import "bootstrap/dist/css/bootstrap.min.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TargetArticles } from "./targetArticles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export function CommunityPage(props: any) {
  return (
    <div>
      <Stack>
        <>
          <Swiper
            /*@ts-ignore*/
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            // onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="mySwiper">
            <SwiperSlide className="news_frame">
              <Box className="third_slide">
                <a
                  className="news_word color-theme-secondary-background desktop"
                  role="presentation"
                  href="https://www.usmagazine.com/celebrity-news/">
                  NEWS
                </a>
              </Box>
            </SwiperSlide>
            <SwiperSlide className="news_frame_first">
              <Stack marginLeft={"39px"} width={"70%"}>
                <Box className="news_art_first">Interesting Articles:</Box>
                <Stack>
                  <Box className="news_art">
                    Operation Mincemeat': The Wild Spy Deception That Helped Win
                    WWII
                  </Box>
                  <Box className="news_info">
                    In November 1942, Winston Churchill delivered a speech to
                    the British House of Commons that laid bare the Allied
                    strategy for defeating Nazi Germany and its Axis sidekick in
                    Europe, Italy. The Allies would first take Northern Africa,
                    then invade Europe through "the underbelly of the Axis,
                    especially Italy," said Churchill. (He didn't call it the
                    "soft underbelly," by the way — that's a misquotation.) .{" "}
                    <span style={{ marginTop: "158px" }}>
                      <LaunchIcon />
                    </span>
                    <span className="more_btn">
                      <a href="https://history.howstuffworks.com/historical-events/operation-mincemeat.htm">
                        more
                      </a>
                    </span>
                  </Box>
                </Stack>
              </Stack>
              <Stack
                width={"25%"}
                marginRight={"10px"}
                marginTop={"20px"}
                marginBottom={"10px"}
                border={"1px solid black"}>
                <Box>
                  {" "}
                  <img
                    className="second_img"
                    src="https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL29wZXJhdGlvbi1taW5jZW1lYXQtMS5qcGciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjgyOH19fQ=="
                    alt="Charles Cholmondeley and Ewen Montagu"></img>
                </Box>
                <Box className="img_desc">
                  Charles Cholmondeley (L) and Ewen Montagu, two of the British
                  intelligence officers involved in the planning of Operation
                  Mincemeat, are shown in front of the vehicle transporting the
                  body of Glyndwr Michael for pick up by submarine.
                </Box>
              </Stack>
            </SwiperSlide>
            <SwiperSlide className="news_frame">
              <iframe
                width="100%"
                height="360px"
                src="https://www.youtube.com/embed/tJfERzrG-D8"
                title="How an Electric Car Works? Its Parts &amp; Functions [Explained]"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
            </SwiperSlide>
          </Swiper>
        </>
        <Container>
          <Box sx={{ width: "70%", typography: "body1", marginTop: "20px" }}>
            <TabContext value={props.value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={props.handleChange}
                  aria-label="lab API tabs example">
                  <Tab label="All Posts" value="1" />
                  <Tab label="Celebrity" value="2" />
                  <Tab label="Reviews" value="3" />
                  <Tab label="Stories" value="4" />
                </TabList>
              </Box>
              <Box className="article_main">
                <TabPanel value="1">
                  <TargetArticles
                    targetBoArticles={props.targetBoArticles}
                    setArticlesRebuild={props.setArticlesRebuild}
                  />
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <TargetArticles
                    targetBoArticles={props.targetBoArticles}
                    setArticlesRebuild={props.setArticlesRebuild}
                  />
                </TabPanel>
                <TabPanel value="3">
                  {" "}
                  <TargetArticles
                    targetBoArticles={props.targetBoArticles}
                    setArticlesRebuild={props.setArticlesRebuild}
                  />
                </TabPanel>
                <TabPanel value="4">
                  {" "}
                  <TargetArticles
                    targetBoArticles={props.targetBoArticles}
                    setArticlesRebuild={props.setArticlesRebuild}
                  />
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Container>
      </Stack>
    </div>
  );
}
