import { createSelector } from "@reduxjs/toolkit";
import { AppRootState } from "../../types/screen";

const selectCommunityPage = (state: AppRootState) => state.communityPage;
export const retrieveTargetBoArticles = createSelector(
  selectCommunityPage,
  (CommunityPage) => CommunityPage.targetBoArticles
);
export const retrieveChosenComment = createSelector(
  selectCommunityPage,
  (communityPage) => communityPage.comments
);
export const retrieveCreateReply = createSelector(
  selectCommunityPage,
  (communityPage) => communityPage.createReply
);
