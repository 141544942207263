import { Box, Container, Rating, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { retrieveReviews } from "./selector";
import ProductApiService from "../../apiServices/productApiService";
import { serverApi } from "../../../lib/consfig";
import { BoArticle } from "../../types/boArticle";
import { setReviews } from "./slice";
import CommunityApiService from "../../apiServices/communityApiService";
import { SearchObj } from "../../types/others";
import ReactHtmlParser from "html-react-parser";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setReviews: (data: BoArticle[]) => dispach(setReviews(data)),
});
//REDUX SELECTOR
const reviewRetriever = createSelector(retrieveReviews, (reviews) => ({
  reviews,
}));

export function Reviews() {
  //INITIALIZATION
  const { setReviews } = actionDispatch(useDispatch());
  const { reviews } = useSelector(reviewRetriever);
  const [memberReview, setMemberReview] = useState({
    order: "createdAt",
    page: 1,
    limit: 12,
    bo_id: "review",
  });

  useEffect(() => {
    const communityService = new CommunityApiService();
    communityService
      .getTargetArticles(memberReview)
      .then((data) => setReviews(data))
      .catch((err) => console.log(err));
  }, [memberReview]);

  return (
    <div className="review_frame">
      <Container>
        <Box className="best_seller">Real reviews from real customers </Box>

        <>
          <Swiper
            /*@ts-ignore*/
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            className="mySwiper">
            {reviews?.map((article: BoArticle) => {
              const image_path = `${serverApi}/${article?.member_data?.mb_image?.replace(
                /\\/g,
                "/"
              )}`;
              const removeEmptyTags = (content: any) => {
                // Remove empty <p> and <br> tags
                const cleanedContent = content
                  .replace(/<p[^>]*><\/p>/g, "")
                  .replace(/<br[^>]*>/g, "");

                return cleanedContent;
              };

              const removeFirstImage = (content: any) => {
                // Use a regular expression to find and remove the first <img> tag
                const modifiedContent = content.replace(/<img[^>]*>/, "");

                return modifiedContent;
              };

              const truncateText = (text: any, maxLength: any) => {
                const words = text.split(" ");
                if (words.length > maxLength) {
                  return words.slice(3, maxLength);
                }
                return text;
              };

              // Remove empty <p> and <br> tags from art_content
              const contentWithoutEmptyTags = removeEmptyTags(
                article?.art_content
              );

              const contentWithoutFirstImage = removeFirstImage(
                contentWithoutEmptyTags
              );

              // Truncate the content to the first 100 words
              const truncatedContent = truncateText(
                contentWithoutFirstImage,
                40
              );
              return (
                <SwiperSlide>
                  {" "}
                  <Stack className="review_box">
                    <Stack
                      flexDirection={"row"}
                      marginLeft={"10px"}
                      marginTop={"10px"}>
                      <Box className="img_box">
                        <img style={{ width: "75%" }} src={image_path} />
                      </Box>
                      <Stack className="user_info">
                        <Box className="user_name">
                          {article.member_data.mb_nick}
                        </Box>
                        <Box className="user_type">
                          {article.member_data.mb_type}
                        </Box>
                        <Box>
                          {" "}
                          <Rating
                            name="half-rating"
                            defaultValue={3.5}
                            precision={0.5}
                            style={{ width: "24px", height: "24px" }}
                          />
                        </Box>
                      </Stack>
                    </Stack>
                    <Box className="user_description">
                      {ReactHtmlParser(truncatedContent)}
                    </Box>
                  </Stack>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      </Container>
    </div>
  );
}
