import { Box, Container, Stack } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper/modules";

export function Events() {
  return (
    <div className="events_frame">
      <Container>
        <Box className="best_seller">Events</Box>
        <>
          <Swiper
            /*@ts-ignore*/
            slidesPerView={1}
            centeredSlides={false}
            slidesPerGroupSkip={1}
            grabCursor={true}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              769: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
            }}
            scrollbar={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Keyboard, Scrollbar, Navigation, Pagination]}
            className="mySwiper">
            <SwiperSlide>
              <Box className="event_box">
                <Stack>
                  <Box className="sale_word">20% SLAE</Box>
                  <Stack className="sale_box">
                    <Stack className="number_sale" flexDirection={"row"}>
                      <PhoneIcon />
                      <Box>010-7877-6599</Box>
                    </Stack>
                    <Box className="info_sale">
                      15~30-June get your 20% sale of serving
                    </Box>
                    <Stack
                      flexDirection={"row"}
                      className="date_sale"
                      marginLeft={"260px"}>
                      <EditCalendarIcon />
                      <Box className="datedate_sale"> 2022/6/10</Box>
                      <LocationOnIcon />
                      <Box>South Korea, Busan</Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box className="event_box">
                <Stack>
                  <Box className="sale_word">20% SLAE</Box>
                  <Stack className="sale_box">
                    <Stack flexDirection={"row"} marginLeft={"390px"}>
                      <PhoneIcon />
                      <Box>010-7877-6599</Box>
                    </Stack>
                    <Box marginLeft={"15px"}>
                      15~30-June get your 20% sale of serving
                    </Box>
                    <Stack flexDirection={"row"} marginLeft={"260px"}>
                      <EditCalendarIcon />
                      <Box marginRight={"10px"}>2022/6/10</Box>
                      <LocationOnIcon />
                      <Box>South Korea, Busan</Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box className="event_box">
                <Stack>
                  <Box className="sale_word">20% SLAE</Box>
                  <Stack className="sale_box">
                    <Stack flexDirection={"row"} marginLeft={"390px"}>
                      <PhoneIcon />
                      <Box>010-7877-6599</Box>
                    </Stack>
                    <Box marginLeft={"15px"}>
                      15~30-June get your 20% sale of serving
                    </Box>
                    <Stack flexDirection={"row"} marginLeft={"260px"}>
                      <EditCalendarIcon />
                      <Box marginRight={"10px"}>2022/6/10</Box>
                      <LocationOnIcon />
                      <Box>South Korea, Busan</Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </SwiperSlide>
          </Swiper>
        </>
      </Container>
    </div>
  );
}
