import { Box } from "@mui/joy";
import {
  Container,
  Stack,
  Button,
  Rating,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Badge from "@mui/material/Badge";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import { setProducts } from "./slice";
import { Product } from "../../types/products";
import { retrieveProducts } from "./selector";
import { useDispatch, useSelector } from "react-redux";
import { ProductSearchObj } from "../../types/others";
import { useHistory } from "react-router-dom";
import ProductApiService from "../../apiServices/productApiService";
import { Definer } from "../../../lib/Definer";
import MemberApiService from "../../apiServices/memberApiService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import assert from "assert";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import { serverApi } from "../../../lib/consfig";
const product_list = Array.from(Array(8).keys());
const product_type = Array.from(Array(6).keys());
//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setProducts: (data: Product[]) => dispach(setProducts(data)),
});
//REDUX SELECTOR
const productsRetriever = createSelector(retrieveProducts, (products) => ({
  products,
}));
export function OilProducts(props: any) {
  //INITIALIZATION
  const { setProducts } = actionDispatch(useDispatch());
  const { products } = useSelector(productsRetriever);
  let [productsData, setProductsData] = useState<ProductSearchObj>({
    page: 1,
    limit: 6,
    order: "createdAt",
    product_collection_id: "manual transmission",
    product_collection: "penzOil",
  });
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  const history = useHistory();

  useEffect(() => {
    const productService = new ProductApiService();
    productService
      .getTargetProductsElectric(productsData)
      .then((data) => setProducts(data))
      .catch((err) => console.log(err));
  }, [productRebuild, productsData]);

  //HANDLERS
  const searchCollectionHandler = (collection: string) => {
    productsData.page = 1;
    productsData.product_collection = collection;
    setProductsData({ ...productsData });
  };

  const targetLikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const chosenProductHandler = (id: string) => {
    history.push(`/model/product/${id}`);
  };
  const handlePaginationChange = (event: any, value: number) => {
    productsData.page = value;
    setProductsData({ ...productsData });
  };
  return (
    <Container className="model_frame">
      <Stack flexDirection={"row"}>
        <Stack className="model_first_frame">
          <Box className="model_intro">Oil and Fluids</Box>
          <Stack className="first_product_box">
            <Stack
              className="hover_product"
              onClick={() => searchCollectionHandler("penzOil")}
              flexDirection={"row"}>
              <Box className="mdoel_first_frame_img">
                <img src="/oils/r1.jpeg" />
              </Box>
              <Box className="product_name">Pennzoil </Box>
            </Stack>
            <Stack
              className="hover_product"
              onClick={() => searchCollectionHandler("castrolOil")}
              flexDirection={"row"}>
              <Box className="mdoel_first_frame_img">
                <img src="/oils/r1.jpeg" />
              </Box>
              <Box className="product_name">Castrol</Box>
            </Stack>
          </Stack>
        </Stack>
        <Box className="second_frame_oil">
          {products?.map((product: Product) => {
            // console.log("product:", product?.me_liked[0]);
            const image_path = `${serverApi}/${product?.product_images[0].replace(
              /\\/g,
              "/"
            )}`;
            return (
              <Stack className="oil_box" key={`${product._id}`}>
                <Box className="oil_img">
                  <img
                    src={image_path}
                    onClick={() => chosenProductHandler(product._id)}
                  />
                  <Stack
                    className="checkbox"
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"200px"}>
                    <Box>
                      <Badge
                        badgeContent={product.product_likes}
                        color="primary">
                        <Checkbox
                          icon={
                            <ThumbUpIcon
                              style={{ color: "#CDCDCD", border: "black" }}
                            />
                          }
                          id={product._id}
                          checkedIcon={
                            <ThumbUpIcon style={{ color: "#003A92" }} />
                          }
                          onClick={targetLikeBest}
                          checked={
                            product?.me_liked &&
                            product?.me_liked[0]?.my_favorite
                              ? true
                              : false
                          }
                        />
                      </Badge>
                    </Box>
                    <Box
                      onClick={(e) => {
                        props.onAdd(product);
                        e.stopPropagation();
                      }}
                      fontSize={"20px"}
                      style={{ cursor: "pointer" }}>
                      <ShoppingCartCheckoutOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: "2.5rem",
                          color: "white",
                        }}
                      />
                    </Box>
                    <Box>
                      <Badge
                        badgeContent={product.product_dislikes}
                        color="primary">
                        <Checkbox
                          id={product._id}
                          icon={
                            <ThumbDownAltIcon
                              style={{ color: "#CDCDCD", border: "black" }}
                            />
                          }
                          // id={`${index}`}
                          checkedIcon={
                            <ThumbDownAltIcon style={{ color: "#003A92" }} />
                          }
                          onClick={targetDislikeBest}
                          checked={
                            product?.me_unliked &&
                            product?.me_unliked[0]?.my_favorite
                              ? true
                              : false
                          }
                        />
                      </Badge>
                    </Box>
                  </Stack>
                </Box>
                <Stack className="price_box">
                  <Box className="oil_name">{product.product_name}</Box>
                  <Box marginLeft={"10px"}>
                    {" "}
                    <Rating
                      name="half-rating"
                      defaultValue={3.5}
                      precision={0.5}
                      style={{ width: "24px", height: "24px" }}
                    />
                  </Box>
                  <Box className="oil_price">{product.product_description}</Box>
                  <Box className="oil_price">{product.product_price}$</Box>
                </Stack>
              </Stack>
            );
          })}{" "}
          <Box className="bottom_box_oil">
            <Pagination
              count={productsData.page >= 3 ? productsData.page + 1 : 3}
              page={productsData.page}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                  color="secondary"
                />
              )}
              onChange={handlePaginationChange}
            />
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}
