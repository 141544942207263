import { Container } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { VisitOtherPage } from "./visitOtherPgae";
import { VisitMyPage } from "./visitMyPage";
import {
  setChosenComment,
  setChosenCreateReply,
  setChosenMember,
} from "../../screens/ModelPage/slice";
import { Member } from "../../types/user";
import {
  retrieveChosenComment,
  retrieveChosenMember,
  retrieveProducts,
} from "../../screens/OilandFluidsPage/selector";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommentApiService from "../../apiServices/commentApiService";
import { verifyMemberData } from "../../apiServices/verify";
import assert from "assert";
import { Definer } from "../../../lib/Definer";
import { BoArticle } from "../../types/boArticle";
import {
  setChosenMemberBoArticles,
  setChosenSingleBoArticle,
} from "../../screens/MyPage/slice";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import { Comment, ReplyContent } from "../../types/comment";
import {
  retrieveChosenMemberBoArticles,
  retrieveChosenSingleBoArticle,
} from "./selector";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import MemberApiService from "../../apiServices/memberApiService";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setChosenMember: (data: Member) => dispach(setChosenMember(data)),
  setChosenComment: (data: Comment[]) => dispach(setChosenComment(data)),
  setChosenCreateReply: (data: ReplyContent[]) =>
    dispach(setChosenCreateReply(data)),
  setChosenMemberBoArticles: (data: BoArticle[]) =>
    dispach(setChosenMemberBoArticles(data)),
  setChosenSingleBoArticle: (data: BoArticle) =>
    dispach(setChosenSingleBoArticle(data)),
});
//REDUX SELECTOR
const productsRetriever = createSelector(retrieveProducts, (products) => ({
  products,
}));
const chosenSingleBoArticleRetriever = createSelector(
  retrieveChosenSingleBoArticle,
  (chosenSingleBoArticle) => ({
    chosenSingleBoArticle,
  })
);
const chosenMemberBoArticlesRetriever = createSelector(
  retrieveChosenMemberBoArticles,
  (chosenMemberBoArticles) => ({
    chosenMemberBoArticles,
  })
);
const chosenMemberRetriever = createSelector(
  retrieveChosenMember,
  (chosenMember) => ({
    chosenMember,
  })
);
const chosenCommentRetriever = createSelector(
  retrieveChosenComment,
  (comments) => ({
    comments,
  })
);
const replyContentRetriever = createSelector(
  retrieveChosenComment,
  (createReply) => ({
    createReply,
  })
);
export function MyPage(props: any) {
  const query = useQuery();
  const chosen_mb_id: string | null = query.get("mb_id") ?? null;
  const chosen_art_id: string | null = query.get("art_id") ?? null;
  const editorRef = useRef();
  const { setChosenComment, setChosenSingleBoArticle } = actionDispatch(
    useDispatch()
  );
  let { mb_id } = useParams<{ mb_id: string }>();
  const { comments } = useSelector(chosenCommentRetriever);
  const [commentTerm, setCommentTerm] = useState("");
  const [replyCommentTerm, setReplyCommentTerm] = useState("");
  const { chosenSingleBoArticle } = useSelector(chosenSingleBoArticleRetriever);
  const [commentRebuild, setCommentRebuild] = useState<Date>(new Date());
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  /*****test */
  const [replyingToCommentId, setReplyingToCommentId] = useState<string | null>(
    null
  );
  const [toggle, setToggle] = useState(true);

  const dishRelatedProcess = async () => {
    try {
      const commentService = new CommentApiService();
      console.log("chosen_art_id", mb_id);
      const comments: Comment[] = await commentService.getComment(mb_id);
      // console.log("******", product);
      setChosenComment(comments);
    } catch (err) {
      console.log("dishRelatedProcess", err);
    }
  };

  useEffect(() => {
    dishRelatedProcess().then();
  }, [commentRebuild]);

  // //HANDLERS

  const handleGetComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentTerm(event.target.value);
  };

  const handleGetReplyComment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReplyCommentTerm(event.target.value);
  };

  const createReplyComment = async (e: any, commentId: string) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);
      console.log("commentIdddddddddd:", commentId);

      const commentService = new CommentApiService();
      const createReplyData: ReplyContent[] =
        await commentService.createReplyComment({
          parent_comment_id: commentId,
          reply_content: replyCommentTerm,
          mb_id: verifyMemberData._id,
        });
      setChosenCreateReply(createReplyData);
      assert.ok(createReplyData, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setCommentRebuild(new Date());
      setReplyCommentTerm(e.target.value);
      setToggle(!toggle);
    } catch (err) {
      console.log("createComment ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };

  const handleCommentClick = (commentId: string) => {
    setReplyingToCommentId(commentId);
  };
  const targetDislikeComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "comment",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetLikeComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "comment",
        });

      assert.ok(like_result, Definer.general_err1);

      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  let member = useRouteMatch();

  console.log(member);
  return (
    <div>
      <Switch>
        <Route path={`${member.path}/other`}>
          <VisitOtherPage
            chosen_mb_id={chosen_mb_id}
            chosen_art_id={chosen_art_id}
            comments={comments}
            commentTerm={commentTerm}
            replyCommentTerm={replyCommentTerm}
            commentRebuild={commentRebuild}
            replyingToCommentId={replyingToCommentId}
            toggle={toggle}
            handleGetComment={handleGetComment}
            handleGetReplyComment={handleGetReplyComment}
            createReplyComment={createReplyComment}
            handleCommentClick={handleCommentClick}
            targetLikeCommen={targetLikeComment}
            targetDislikeComment={targetDislikeComment}
            setCommentTerm={setCommentTerm}
            setCommentRebuild={setCommentRebuild}
          />
        </Route>
        <Route path={`${member.path}`}>
          <VisitMyPage
            chosen_mb_id={chosen_mb_id}
            chosen_art_id={chosen_art_id}
          />
        </Route>
      </Switch>
    </div>
  );
}
export { retrieveChosenMemberBoArticles };
