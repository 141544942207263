import { Button } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import "../../../css/up.css";

const Up = (props: any) => {
  /** INITIALIZATIONS **/
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="homepage">
      {/* <button>Scroll to Top</button> */}

      {showScrollButton && (
        <Button
          className="scroll-to-top-button "
          onClick={scrollUp}
          style={{
            position: "fixed",
            bottom: "30px",
            right: "50px",
            height: "50px",
            width: "50px",
            fontSize: "50px",
          }}>
          ^
        </Button>
      )}
    </div>
  );
};

export default Up;
