import { Box, Button, Container, Stack } from "@mui/material";
import React from "react";

export function GetStarted() {
  return (
    <div className="homepage_frame">
      <Container>
        <Stack className="homepage_container" flexDirection={"row"}>
          <Stack>
            <Box className="homepage_intro">
              Price is what you pay. quality is what you get
            </Box>
            <Box className="homepage_info">
              Our expert staff works to provide you with the most expansive
              catalog of Genuine, Aftermarket, and Performance parts for your
              car. With our service kits, performance parts, and ever-growing
              list of partners, you can be sure to find what you need for your
              car at ECS Tuning.
            </Box>
            <Stack className="getstarted" flexDirection={"row"}>
              <Button
                className="get_started_btn"
                variant="contained"
                color="secondary">
                Get started
              </Button>
              <Button
                className="learn_btn"
                variant="contained"
                color="secondary">
                Learn More
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <Box>
              <img
                className="first_img_get"
                // style={{ height: "303px", width: "474px" }}
                src="/homepage/part1.png"
              />
            </Box>
            <Box marginLeft={"35%"}>
              <img className="first_img" src="/homepage/part2.png" />
            </Box>
          </Stack>
        </Stack>
        <Stack className="car_icons">
          <img
            style={{ maxHeight: "73px", maxWidth: "141px" }}
            src="/homepage/chevrolet.png"
          />
          <img
            style={{ maxHeight: "74px", maxWidth: "190px" }}
            src="/homepage/genesis.png"
          />
          <img
            style={{ maxHeight: "67px", maxWidth: "123px" }}
            src="/homepage/hyunadai.png"
          />
          <img
            style={{ maxHeight: "66px", maxWidth: "129px" }}
            src="/homepage/kia.png"
          />
          <img
            style={{ maxHeight: "75px", maxWidth: "186px" }}
            src="/homepage/minicar.png"
          />
          <img
            className="nissan"
            style={{ maxHeight: "102px", maxWidth: "111px" }}
            src="/homepage/nissan.png"
          />
          <img
            className="nissan"
            style={{ maxHeight: "118px", maxWidth: "166px" }}
            src="/homepage/samsun.png"
          />
        </Stack>
      </Container>
    </div>
  );
}
