import { Container, Stack, Button } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { createSelector } from "@reduxjs/toolkit";
import { retrieveProcessOrders } from "./selector";
import { useSelector } from "react-redux";
import { verifyMemberData } from "../../apiServices/verify";
import OrderApiService from "../../apiServices/orderApiService";
import {
  sweetErrorHandling,
  sweetFailureProvider,
} from "../../../lib/sweetAlert";
import { Order } from "../../types/order";
import { Product } from "../../types/products";
import { serverApi } from "../../../lib/consfig";

//REDUX SELECTOR
const processOrdersRetriever = createSelector(
  retrieveProcessOrders,
  (processOrders) => ({
    processOrders,
  })
);

export function ProcessOrders(props: any) {
  /**INITIALIZATION */
  const { processOrders } = useSelector(processOrdersRetriever);
  /** HANDLERS **/
  const finishOrderHandler = async (event: any) => {
    try {
      const order_id = event.target.value;
      const data = { order_id: order_id, order_status: "FINISHED" };

      if (!verifyMemberData) {
        sweetFailureProvider(`Please login first`, true);
      }

      let confirmation = window.confirm(
        `Buyurtmani olganingizni tasdiqlaysizmi?`
      );
      if (confirmation) {
        const orderService = new OrderApiService();
        await orderService.updateOrderStatus(data);
        //refresh builder
        props.setOrderRebuild(new Date());
      }
    } catch (err) {
      console.log(`finishOrderHandler, ERROR::`, err);
      sweetErrorHandling(err).then();
    }
  };

  return (
    <Container className="orders_page">
      {processOrders?.map((order: Order) => {
        return (
          <Stack className="order_process_box">
            <Box className="scroll_box">
              {order?.order_items.map((item) => {
                const product: Product = order?.product_data.filter(
                  (ele) => ele._id === item?.product_id
                )[0];
                const image_path = `${serverApi}/${product?.product_images[0].replace(
                  /\\/g,
                  "/"
                )}`;
                return (
                  <Stack className="order_name_line">
                    <Box className="item_img">
                      <img src={image_path} />
                    </Box>
                    <Box className="item_name">{product?.product_name}</Box>
                    <Stack className="cal_price">
                      <Box>${item?.item_price}</Box>
                      <Box>X</Box>
                      <Box>{item?.item_quantity}</Box>
                      <Box>=</Box>
                      <Box>${item?.item_price * item?.item_quantity}</Box>
                    </Stack>
                  </Stack>
                );
              })}
            </Box>
            <Stack className="total_line">
              <Box className="item_fontstyle_first">Item price:</Box>
              <Box>
                ${order?.order_total_amount - order?.order_delivery_cost}
              </Box>
              <Box>+</Box>
              <Box className="item_fontstyle">Deivery cost:</Box>
              <Box>${order?.order_delivery_cost}</Box>
              <Box>=</Box>
              <Box className="item_fontstyle">Total price:</Box>
              <Box>${order?.order_total_amount}</Box>

              <Button
                value={order._id}
                onClick={finishOrderHandler}
                className="finish_btn"
                variant="contained">
                Finish Order
              </Button>
            </Stack>
          </Stack>
        );
      })}
    </Container>
  );
}
