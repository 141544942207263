import { createSelector } from "@reduxjs/toolkit";
import { AppRootState } from "../../types/screen";

const selectModelPage = (state: AppRootState) => state.electrCarPage;
export const retrieveProducts = createSelector(
  selectModelPage,
  (ElectrCar) => ElectrCar.products
);
export const retrieveChosenProduct = createSelector(
  selectModelPage,
  (ElectrCar) => ElectrCar.chosenProduct
);
