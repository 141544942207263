import { createSelector } from "reselect";
import { AppRootState } from "../../types/screen";

const selectHomePage = (state: AppRootState) => state.homePage;
export const retrieveHotSale = createSelector(
  selectHomePage,
  (HomePage) => HomePage.hotSale
);
export const retrieveBestSeller = createSelector(
  selectHomePage,
  (HomePage) => HomePage.bestSeller
);
export const retrieveNewProducts = createSelector(
  selectHomePage,
  (HomePage) => HomePage.newProducts
);
export const retrieveUsedProduct = createSelector(
  selectHomePage,
  (HomePage) => HomePage.usedProducts
);
export const retrieveEvents = createSelector(
  selectHomePage,
  (HomePage) => HomePage.events
);
export const retrieveReviews = createSelector(
  selectHomePage,
  (HomePage) => HomePage.reviews
);
