import {
  Box,
  Button,
  Container,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { setMemberFollowers, setMemberFollowings } from "./slice";
import { FollowSearchObj, Follower, Following } from "../../types/follow";
import { retrieveMemberFollowers, retrieveMemberFollowings } from "./selector";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FollowApiService from "../../apiServices/followApiService";
import { verifyMemberData } from "../../apiServices/verify";
import assert from "assert";
import { Definer } from "../../../lib/Definer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import { serverApi } from "../../../lib/consfig";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setMemberFollowings: (data: Following[]) =>
    dispach(setMemberFollowings(data)),
});

//REDUX SELECTOR

const memberFollowingsRetriever = createSelector(
  retrieveMemberFollowings,
  (memberFollowings) => ({
    memberFollowings,
  })
);
export function MemberFollowing(props: any) {
  //INITALIZATION
  const history = useHistory();
  const { setFollowRebuild, followRebuild } = props;
  const { setMemberFollowings } = actionDispatch(useDispatch());
  const { memberFollowings } = useSelector(memberFollowingsRetriever);
  const [followingsSearchObj, setFollowingsSearchObj] =
    useState<FollowSearchObj>({ page: 1, limit: 5, mb_id: props.mb_id });
  useEffect(() => {
    const followService = new FollowApiService();
    followService
      .getMemberFollowings(followingsSearchObj)
      .then((data) => setMemberFollowings(data))
      .catch((err) => console.log(err));
  }, [followingsSearchObj, followRebuild]);
  //setMemberFollowers

  /***HANDLERS */
  const handlePaginationChange = (event: any, value: number) => {
    followingsSearchObj.page = value;
    setFollowingsSearchObj({ ...followingsSearchObj });
  };

  const unsubscribeHandler = async (e: any, id: string) => {
    try {
      e.stopPropagation();
      assert.ok(verifyMemberData, Definer.auth_err1);

      const followService = new FollowApiService();
      await followService.unsubscribe(id);

      await sweetTopSmallSuccessAlert(`unsubscribed successfully`, 700, false);
      setFollowRebuild(!followRebuild);
    } catch (err: any) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };

  const visitMemberHandler = (mb_id: string) => {
    history.push(`/my-page/other?mb_id=${mb_id}`);
    document.location.reload();
  };
  return (
    <Container style={{ maxHeight: "420px" }}>
      {memberFollowings?.map((following: Following) => {
        const image_url = following?.follow_member_data?.mb_image
          ? `${serverApi}/${following?.follow_member_data?.mb_image}`
          : "/users/17jk9a.jpg";
        return (
          <Stack
            onClick={() => visitMemberHandler(following?.follow_id)}
            flexDirection={"row"}
            className="follower_box">
            <Box className="user_img_follower">
              <img className="user_img_follower" src={image_url} />
            </Box>

            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}>
              <Stack marginLeft={"15px"}>
                <Box className="user_mail">
                  {following?.follow_member_data?.mb_type}
                </Box>

                <Box className="user_name">
                  {following?.follow_member_data?.mb_nick}
                </Box>
              </Stack>
              {props.actions_enabled && (
                <Button
                  variant="contained"
                  startIcon={
                    <img
                      src="/icons/follow_icon.svg"
                      style={{ width: "30px", marginLeft: "16px" }}
                    />
                  }
                  className={"Unfollow_btn"}
                  onClick={(e) => unsubscribeHandler(e, following?.follow_id)}>
                  Unfollow
                </Button>
              )}
            </Stack>
          </Stack>
        );
      })}
      <Stack
        marginTop={"15px"}
        direction="row"
        alignItems="center"
        justifyContent="center">
        <Box>
          <Pagination
            count={
              followingsSearchObj.page >= 3 ? followingsSearchObj.page + 1 : 3
            }
            page={followingsSearchObj.page}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
                color="secondary"
              />
            )}
            onChange={handlePaginationChange}
          />
        </Box>
      </Stack>
    </Container>
  );
}
