import { createSlice } from "@reduxjs/toolkit";
import { ElectrCarPageState, ModelPageState } from "../../types/screen";

const initialState: ElectrCarPageState = {
  products: [],
  chosenProduct: null,
};

const ElectrCarPageSlice = createSlice({
  name: "electrCarPage",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setChosenProduct: (state, action) => {
      state.chosenProduct = action.payload;
    },
  },
});

export const { setProducts, setChosenProduct } = ElectrCarPageSlice.actions;
const ElectrCarPageReducer = ElectrCarPageSlice.reducer;
export default ElectrCarPageReducer;
