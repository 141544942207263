import { Box } from "@mui/joy";
import { Container, Stack, Button } from "@mui/material";
import React from "react";
import ThumbUpTwoToneIcon from "@mui/icons-material/ThumbUpTwoTone";
import ThumbDownOffAltTwoToneIcon from "@mui/icons-material/ThumbDownOffAltTwoTone";
import { Products } from "./products";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { ChosenProduct } from "./chosenProduct";
import { HelpPage } from "../HelpPage";

export function ModelPage(props: any) {
  let model = useRouteMatch();
  console.log(model);
  return (
    <div className="model_frame">
      <Switch>
        <Route path={`${model.path}`}></Route>

        <Products onAdd={props.onAdd} />
      </Switch>
      <Switch>
        <Route path={`${model.path}/product/:product_id`}>
          <ChosenProduct onAdd={props.onAdd} />
        </Route>
        <Route path={`${model.path}/help`}>
          <HelpPage />
        </Route>
        <Products onAdd={props.onAdd} />
      </Switch>
    </div>
  );
}
