import React, { useEffect } from "react";
import { Box, Button, Container, Stack } from "@mui/material";
import useDeviceDetect from "../../../lib/responsiveDetector";
import { NavLink } from "react-router-dom";

// import { NavbarHomeBanner } from "./banner";

export function Responsive(props: any) {
  const { isMobile } = useDeviceDetect();

  if (isMobile()) {
    return (
      <div>
        {/* <NavbarHome /> */}
        <div className="under-construction">
          <p className="construction">Mobile version is coming soon! 👨‍💻</p>
          <p className="construction">Please use our desktop version 😊</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
