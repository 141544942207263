import { Box, Container, Stack, Button, Rating, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react"; // Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../css/swiper.css";

// import required modules
import {
  EffectCoverflow,
  Keyboard,
  FreeMode,
  Thumbs,
  Pagination,
  Navigation,
} from "swiper/modules";
import { Product } from "../../types/products";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import {
  setChosenComment,
  setChosenCreateReply,
  setChosenMember,
  setChosenProduct,
  setProducts,
} from "./slice";
import {
  retrieveChosenComment,
  retrieveChosenMember,
  retrieveChosenProduct,
  retrieveProducts,
} from "./selector";
import { useDispatch, useSelector } from "react-redux";
import { CommentSearchObj, ProductSearchObj } from "../../types/others";
import ProductApiService from "../../apiServices/productApiService";
import { serverApi } from "../../../lib/consfig";
import { useHistory, useParams } from "react-router-dom";
import { Member } from "../../types/user";
import MemberApiService from "../../apiServices/memberApiService";
import assert from "assert";
import { Definer } from "../../../lib/Definer";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import { Comment, ReplyContent } from "../../types/comment";
import { verifyMemberData } from "../../apiServices/verify";
import CommentApiService from "../../apiServices/commentApiService";
import moment from "moment";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setProducts: (data: Product[]) => dispach(setProducts(data)),
  setChosenProduct: (data: Product) => dispach(setChosenProduct(data)),
  setChosenMember: (data: Member) => dispach(setChosenMember(data)),
  setChosenComment: (data: Comment[]) => dispach(setChosenComment(data)),
  setChosenCreateReply: (data: ReplyContent[]) =>
    dispach(setChosenCreateReply(data)),
});
//REDUX SELECTOR
const productsRetriever = createSelector(retrieveProducts, (products) => ({
  products,
}));
const chosenProductRetriever = createSelector(
  retrieveChosenProduct,
  (chosenProduct) => ({
    chosenProduct,
  })
);
const chosenMemberRetriever = createSelector(
  retrieveChosenMember,
  (chosenMember) => ({
    chosenMember,
  })
);
const chosenCommentRetriever = createSelector(
  retrieveChosenComment,
  (comments) => ({
    comments,
  })
);
const replyContentRetriever = createSelector(
  retrieveChosenComment,
  (createReply) => ({
    createReply,
  })
);
export function ChosenProduct(props: any) {
  const [activeThumb, setActiveThumb] = useState(null);
  //INITIALIZATION
  let { product_id } = useParams<{ product_id: string }>();
  let { parent_comment_id } = useParams<{ parent_comment_id: string }>();
  const { setChosenProduct, setChosenCreateReply, setChosenComment } =
    actionDispatch(useDispatch());
  const { chosenProduct } = useSelector(chosenProductRetriever);
  const { chosenMember } = useSelector(chosenMemberRetriever);
  const { comments } = useSelector(chosenCommentRetriever);
  const { createReply } = useSelector(replyContentRetriever);
  const { setProducts } = actionDispatch(useDispatch());
  const { products } = useSelector(productsRetriever);
  const [commentTerm, setCommentTerm] = useState("");
  const [replyCommentTerm, setReplyCommentTerm] = useState("");
  let [productsData, setProductsData] = useState<ProductSearchObj>({
    page: 1,
    limit: 16,
    order: "createdAt",
    product_collection_id: "manual transmission",
  });
  const [commentRebuild, setCommentRebuild] = useState<Date>(new Date());
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  /*****test */
  const [replyingToCommentId, setReplyingToCommentId] = useState<string | null>(
    null
  );

  const [toggle, setToggle] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const productService = new ProductApiService();
    productService
      .getTargetProductsElectric(productsData)
      .then((data) => setProducts(data))
      .catch((err) => console.log(err));
  }, [productRebuild, productsData]);

  const dishRelatedProcess = async () => {
    try {
      const productService = new ProductApiService();
      const product: Product = await productService.getChosenProduct(
        product_id
      );
      // console.log("******", product);
      setChosenProduct(product);

      const commentService = new CommentApiService();
      const comments: Comment[] = await commentService.getComment(product_id);
      // console.log("******", product);
      setChosenComment(comments);
    } catch (err) {
      console.log("dishRelatedProcess", err);
    }
  };

  useEffect(() => {
    dishRelatedProcess().then();
  }, [productRebuild, commentRebuild]);

  //HANDLERS
  const searchCollectionHandler = (collection: string) => {
    productsData.page = 1;
    productsData.product_collection = collection;
    setProductsData({ ...productsData });
  };

  const chosenProductHandler = (id: string) => {
    history.push(`/model/product/${id}`);
    setProductRebuild(new Date());
  };
  const handleGetComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentTerm(event.target.value);
  };

  const handleGetReplyComment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReplyCommentTerm(event.target.value);
  };
  const createComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const commentService = new CommentApiService();
      const createComment: any = await commentService.createComment({
        comment_content: commentTerm,
        mb_id: verifyMemberData._id,
        product_id: chosenProduct?._id,
      });
      assert.ok(createComment, Definer.general_err1);

      await sweetTopSmallSuccessAlert("success", 700, false);
      setCommentRebuild(new Date());
      setCommentTerm(e.target.value);
    } catch (err) {
      console.log("createComment ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetLikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);

      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetLikeComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "comment",
        });

      assert.ok(like_result, Definer.general_err1);

      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "comment",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };

  const createReplyComment = async (e: any, commentId: string) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);
      console.log("commentIdddddddddd:", commentId);

      const commentService = new CommentApiService();
      const createReplyData: ReplyContent[] =
        await commentService.createReplyComment({
          parent_comment_id: commentId,
          reply_content: replyCommentTerm,
          mb_id: verifyMemberData._id,
        });
      setChosenCreateReply(createReplyData);
      assert.ok(createReplyData, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setCommentRebuild(new Date());
      setReplyCommentTerm(e.target.value);
      setToggle(!toggle);
    } catch (err) {
      console.log("createComment ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  // Time Handler
  const formatTimeAgo = (createdTime: Date) => {
    const now = moment();
    const createdMoment = moment(createdTime);

    const seconds = now.diff(createdMoment, "seconds");
    if (seconds < 60) {
      return `${seconds}s`;
    }

    const minutes = now.diff(createdMoment, "minutes");
    if (minutes < 60) {
      return `${minutes}m`;
    }

    const hours = now.diff(createdMoment, "hours");
    if (hours < 24) {
      return `${hours}h`;
    }

    const days = now.diff(createdMoment, "days");
    if (days < 7) {
      return `${days}d`;
    }

    const weeks = now.diff(createdMoment, "weeks");
    if (weeks < 52) {
      return `${weeks}w`;
    }

    const years = now.diff(createdMoment, "years");
    return `${years}y`;
  };

  const handleCommentClick = (commentId: string) => {
    setReplyingToCommentId(commentId);
  };
  let lengthComment = [];
  let commentLength = comments?.length;
  lengthComment.push(commentLength);
  return (
    <Container className="comment_product_frame">
      <Box className="header_chosen_product">
        <Stack>
          <img src="/homepage/part1.png" className="img_header" />
          <Box className="intro_header">Body Components</Box>
          <Stack className="swiper_header">
            <Swiper
              /*@ts-ignore*/

              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper">
              <SwiperSlide
                onClick={() => searchCollectionHandler("body Components")}>
                <img src="/hotsale/part.png" />
                <Box className="swiper_name">Body Components</Box>
              </SwiperSlide>
              <SwiperSlide onClick={() => searchCollectionHandler("bumpers")}>
                <img src="/navbarchosen/bumper.png" />
                <Box className="swiper_name">Bumpers</Box>
              </SwiperSlide>

              <SwiperSlide onClick={() => searchCollectionHandler("hardware")}>
                <img src="/navbarchosen/hardware.png" />
                <Box className="swiper_name">Hardware</Box>
              </SwiperSlide>
              <SwiperSlide onClick={() => searchCollectionHandler("heating")}>
                <img src="/navbarchosen/Heating.png" />
                <Box className="swiper_name">Heating & Cooling</Box>
              </SwiperSlide>
              <SwiperSlide onClick={() => searchCollectionHandler("interior")}>
                <img src="/navbarchosen/Interior.png" />
                <Box className="swiper_name">Interior</Box>
              </SwiperSlide>
              <SwiperSlide onClick={() => searchCollectionHandler("lighting")}>
                <img src="/navbarchosen/Lighting.png" />
                <Box className="swiper_name">Lighting</Box>
              </SwiperSlide>
              <SwiperSlide
                onClick={() => searchCollectionHandler("transmission")}>
                <img src="/navbarchosen/Transmission.png" />
                <Box className="swiper_name">Transmission</Box>
              </SwiperSlide>
              <SwiperSlide onClick={() => searchCollectionHandler("oil")}>
                <img src="/navbarchosen/Transmission.png" />
                <Box className="swiper_name">oil</Box>
              </SwiperSlide>
            </Swiper>
            <img className="img_mechanic" src="/Navbar_icons/mechanic.png" />
          </Stack>
        </Stack>
      </Box>
      <Stack style={{ width: "100%", display: "flex" }} flexDirection={"row"}>
        <Box className="prev_btn retsaurant-prev">
          <ArrowBackIosNewIcon
            sx={{ fontSize: 40 }}
            style={{ color: "black" }}
          />
        </Box>
        <Swiper
          className="restaurant_avatars_wrapper"
          /*@ts-ignore*/
          slidesPerView={7}
          centeredSlides={false}
          spaceBetween={30}
          navigation={{
            nextEl: ".restaurant-next",
            prevEl: ".restaurant-prev",
          }}>
          {products?.map((product: Product) => {
            const image_path = `${serverApi}/${product?.product_images[0].replace(
              /\\/g,
              "/"
            )}`;
            return (
              <SwiperSlide
                key={product._id}
                onClick={() => chosenProductHandler(product._id)}
                style={{ cursor: "pointer" }}
                /*@ts-ignore*/
                thumbs={{ swiper: activeThumb }}
                className="restaurant_avatars">
                <img src={image_path} />
                <span>{product.product_name}</span>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Box className="next_btn restaurant-next" style={{ color: "black" }}>
          <ArrowForwardIosIcon sx={{ fontSize: 40 }} />
        </Box>{" "}
      </Stack>

      <Stack marginTop={"20px"} flexDirection={"row"}>
        <Stack className="swipercon2">
          <Box className="swiper_container_unique">
            <>
              <Swiper
                /*@ts-ignore*/
                spaceBetween={10}
                id={chosenProduct?._id}
                navigation={true}
                thumbs={{ swiper: activeThumb }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2">
                {chosenProduct?.product_images?.map((ele: string) => {
                  const image_path = `${serverApi}/${ele}`;
                  return (
                    <SwiperSlide>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={image_path}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <Swiper
                /*@ts-ignore*/
                onSwiper={setActiveThumb}
                /*@ts-ignore*/
                spaceBetween={10}
                /*@ts-ignore*/
                slidesPerView={chosenProduct?.product_images.length}
                freeMode={true}
                id={chosenProduct?._id}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper">
                {chosenProduct?.product_images?.map(
                  (ele: string, index: number) => {
                    const image_path = `${serverApi}/${ele}`;
                    return (
                      <SwiperSlide>
                        <img
                          style={{
                            height: "70px",
                            width: "120px",
                            display: "flex",
                          }}
                          src={image_path}
                          alt={`Slide ${index}`}
                        />
                      </SwiperSlide>
                    );
                  }
                )}
              </Swiper>
            </>
          </Box>
        </Stack>
        <Stack className="second_box">
          <Box className="product_name">
            {chosenProduct?.product_collection_model}-
            {chosenProduct?.product_name}
          </Box>
          <Stack className="product_desc">
            <Box className="yulduz">
              {" "}
              <Box>
                {" "}
                <Rating
                  name="half-rating"
                  defaultValue={3.5}
                  precision={0.5}
                  style={{ width: "24px", height: "24px" }}
                />
              </Box>
            </Box>
            <Box className="product_like">
              <Box>
                <Checkbox
                  icon={
                    <ThumbUpIcon
                      style={{ color: "#CDCDCD", border: "black" }}
                    />
                  }
                  id={chosenProduct?._id}
                  checkedIcon={<ThumbUpIcon style={{ color: "#003A92" }} />}
                  onClick={targetLikeBest}
                  checked={
                    chosenProduct?.me_liked &&
                    chosenProduct?.me_liked[0]?.my_favorite
                      ? true
                      : false
                  }
                />
                <span>{chosenProduct?.product_likes}</span>
              </Box>
            </Box>
            <Box className="product_dislike">
              {" "}
              <Checkbox
                id={chosenProduct?._id}
                icon={
                  <ThumbDownAltIcon
                    style={{ color: "#CDCDCD", border: "black" }}
                  />
                }
                // id={`${index}`}
                checkedIcon={<ThumbDownAltIcon style={{ color: "#003A92" }} />}
                onClick={targetDislikeBest}
                checked={
                  chosenProduct?.me_unliked &&
                  chosenProduct?.me_unliked[0]?.my_favorite
                    ? true
                    : false
                }
              />
              <span>{chosenProduct?.product_dislikes}</span>
            </Box>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "15px",
              }}>
              <RemoveRedEyeIcon sx={{ mr: "10px" }} />
              <span>{chosenProduct?.product_views}</span>
            </div>
          </Stack>
          <Box className="product_description">
            {chosenProduct?.product_description}
          </Box>
          <Box className="line"></Box>
          <Stack className="product_info">
            <Box className="price">{chosenProduct?.product_price}$</Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                props.onAdd(chosenProduct);
                e.stopPropagation();
              }}>
              Add to Cart
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Box className="comments">Comments({lengthComment})</Box>
      <Stack className="chat_frame_product">
        <Box className="chat_content_product">
          {comments?.map((comment: Comment) => {
            const formattedTime = formatTimeAgo(comment?.comment?.createdAt);
            const image_path = `${serverApi}/${comment?.comment?.member_data[0]?.mb_image}`;
            console.log("comment:", comment);
            // Check if this comment has replies
            const hasReplies = comment?.reply_comments?.length ?? 0 > 0;
            // Check if this comment is the one being replied to
            const isReplyingToThisComment = replyingToCommentId === comment._id;

            // console.log("data", image_path);

            return (
              <Stack className="chat_main_product">
                <Box
                  id={comment._id}
                  className="comment_content"
                  flexDirection="row"
                  style={{ display: "flex", alignItems: "center" }}
                  sx={{ m: "10px 0px" }}>
                  <Avatar alt="martin" src={image_path} />

                  <div className="msg_left">
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginBottom: "10px",
                        fontSize: "14px",
                      }}>
                      <div className="user_names">
                        {comment?.comment?.member_data[0]?.mb_nick}
                        <p className="comment_written_date">
                          {formattedTime} ago
                        </p>
                      </div>
                      <li style={{ listStyleType: "none" }}>
                        {comment?.comment?.comment_content}
                      </li>
                    </div>
                    <Box className="comment_likes_product">
                      <Box className="product_like">
                        <Box flexDirection={"row"}>
                          <span style={{ fontSize: "12px" }}>
                            {comment?.comment?.comment_likes}
                          </span>

                          <Checkbox
                            icon={
                              <ThumbUpIcon
                                style={{
                                  color: "#CDCDCD",
                                  border: "black",
                                  fontSize: "16px",
                                }}
                              />
                            }
                            id={comment._id}
                            checkedIcon={
                              <ThumbUpIcon
                                style={{ fontSize: "16px", color: "#003A92" }}
                              />
                            }
                            onClick={targetLikeComment}
                            checked={
                              comment?.comment?.me_liked &&
                              comment?.comment?.me_liked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Box>
                      </Box>
                      <Box className="product_dislike">
                        <span style={{ fontSize: "12px" }}>
                          {comment?.comment?.comment_dislikes}
                        </span>
                        <Checkbox
                          icon={
                            <ThumbDownAltIcon
                              style={{
                                color: "#CDCDCD",
                                border: "black",
                                fontSize: "16px",
                              }}
                            />
                          }
                          id={comment._id}
                          checkedIcon={
                            <ThumbDownAltIcon
                              style={{ fontSize: "16px", color: "#003A92" }}
                            />
                          }
                          onClick={targetDislikeComment}
                          checked={
                            comment?.comment?.me_unliked &&
                            comment?.comment?.me_unliked[0]?.my_favorite
                              ? true
                              : false
                          }
                        />
                      </Box>
                      <Box className="product_reply">
                        <ReplyIcon
                          onClick={() => {
                            setReplyingToCommentId(comment._id);
                            setToggle(!toggle);
                          }}
                          id="replyButton"
                          style={{
                            stroke: "#000",
                            color: "white",
                            fontSize: "1.0rem",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </div>
                </Box>
                {isReplyingToThisComment && !toggle && (
                  <div>
                    <input
                      value={replyCommentTerm}
                      onChange={handleGetReplyComment}
                      className="msg_input"
                      placeholder="Type here ..."
                    />
                    <Button
                      onClick={(e) => {
                        createReplyComment(e, comment._id);
                        // Call the createReplyComment function
                      }} // Pass comment ID to createReplyComment
                      color="secondary"
                      variant="contained"
                      className="send_msg_button">
                      POST
                    </Button>
                  </div>
                )}

                {comment?.reply_comments?.map((replyData: ReplyContent) => {
                  const formattedTim = formatTimeAgo(replyData?.createdAt);
                  const image_path2 = `${serverApi}/${replyData?.member_data[0]?.mb_image}`;
                  console.log("repltData2", replyData);
                  return (
                    <Box
                      className="reply_box"
                      flexDirection="row"
                      style={{ display: "flex", alignItems: "center" }}>
                      {replyData?.member_data[0]?.mb_image ? (
                        <>
                          {" "}
                          <Avatar alt="martin" src={image_path2} />
                        </>
                      ) : null}
                      {replyData?.member_data[0]?.mb_image ? (
                        <>
                          {" "}
                          <div className="msg_left">
                            <div
                              onClick={() => handleCommentClick(comment._id)}
                              style={{
                                marginTop: "10px",
                                marginLeft: "10px",
                                marginBottom: "10px",
                                fontSize: "14px",
                                marginRight: "10px",
                              }}>
                              <div className="user_names">
                                {replyData?.member_data[0]?.mb_nick}
                                <p className="comment_written_date">
                                  {formattedTim} ago
                                </p>
                              </div>
                              <li style={{ listStyleType: "none" }}>
                                {replyData?.reply_content}
                              </li>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Box>
                  );
                })}
              </Stack>
            );
          })}
        </Box>
      </Stack>
      <Box className="chat_bott_product">
        <input
          id="myInput"
          value={commentTerm}
          onChange={handleGetComment}
          className="msg_input"
          placeholder="Type here ..."
        />
        <Button
          onClick={createComment}
          color="secondary"
          variant="contained"
          className="send_msg_button">
          POST
        </Button>
      </Box>
    </Container>
  );
}
