import { createSelector } from "@reduxjs/toolkit";
import { AppRootState } from "../../types/screen";

const selectModelPage = (state: AppRootState) => state.modelPage;
export const retrieveProducts = createSelector(
  selectModelPage,
  (ModelPage) => ModelPage.products
);
export const retrieveChosenProduct = createSelector(
  selectModelPage,
  (ModelPage) => ModelPage.chosenProduct
);
export const retrieveChosenMember = createSelector(
  selectModelPage,
  (ModelPage) => ModelPage.chosenMember
);
export const retrieveChosenComment = createSelector(
  selectModelPage,
  (ModelPage) => ModelPage.comments
);
export const retrieveCreateReply = createSelector(
  selectModelPage,
  (ModelPage) => ModelPage.createReply
);
