import React, { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Button from "@mui/material/Button";
import assert from "assert";
import { Definer } from "../../../lib/Definer";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import MemberApiService from "../../apiServices/memberApiService";
import { verifyMemberData } from "../../apiServices/verify";
import { MemberUpdateData } from "../../types/user";

export function MySettings() {
  /**INITIALIZATION */
  const [file, setFile] = useState(verifyMemberData?.mb_image);

  const [memberUpdate, setMemberUpdate] = useState<MemberUpdateData>({
    mb_nick: "",
    mb_phone: "",
    mb_address: "",
    mb_description: "",
    mb_image: "",
  });

  /***HANDLERS */
  const changeNickHandler = (e: any) => {
    memberUpdate.mb_nick = e.target.value;
    setMemberUpdate({ ...memberUpdate });
  };
  const changeMemberPhoneHandler = (e: any) => {
    memberUpdate.mb_phone = e.target.value;
    setMemberUpdate({ ...memberUpdate });
  };
  const changeMemberAddressHandler = (e: any) => {
    memberUpdate.mb_address = e.target.value;
    setMemberUpdate({ ...memberUpdate });
  };
  const changeMemberDescriptionHandler = (e: any) => {
    memberUpdate.mb_description = e.target.value;
    setMemberUpdate({ ...memberUpdate });
  };

  const handleImagePreviewer = (e: any) => {
    try {
      const file = e.target.files[0];

      const fileType = file["type"],
        validTypes = ["image/jpg", "image/png", "image/jpeg"];
      assert.ok(validTypes.includes(fileType) && file, Definer.input_err2);

      memberUpdate.mb_image = file;
      setMemberUpdate({ ...memberUpdate });
      setFile(URL.createObjectURL(file));
    } catch (err) {
      console.log("ERROR:: handleImagePreviewer", err);
      sweetErrorHandling(err).then();
    }
  };

  const handleSubmitBtn = async () => {
    try {
      const memberService = new MemberApiService();
      const result = await memberService.updateMemberData(memberUpdate);

      assert.ok(result, Definer.general_err1);
      await sweetTopSmallSuccessAlert(
        "Imformation updated successfully",
        700,
        false
      );
      window.location.reload();
    } catch (err) {
      console.log("ERROR:::handleSubmitBtn", err);
      throw err;
    }
  };

  return (
    <Stack className="my_settings_page">
      <Box className="member_media_frame">
        <img
          src={file}
          className="nb_image"
          width={"100px"}
          style={{ borderRadius: "50%" }}
          height="100px"
        />

        <div className="media_change_box">
          <span className="text_rasm">Upload Image</span>
          <p className="text_rasm_1">Only: JPG, JPEG, PNG format!</p>
          <div className="up_del_box">
            <Button
              component="label"
              style={{ minWidth: "0" }}
              onChange={handleImagePreviewer}>
              <CloudDownloadIcon />
              <input type="file" hidden />
            </Button>
          </div>
        </div>
      </Box>

      <Box className="input_frame">
        <div className="long_input">
          <label className="spec_label">Name</label>
          <input
            className="spec_input mb_nick"
            type="text"
            placeholder={verifyMemberData?.mb_nick}
            name="mb_nick"
            onChange={changeNickHandler}
          />
        </div>
      </Box>

      <Box className="input_frame">
        <div className="short_input long_input">
          <label className="spec_label">Phone Number</label>
          <input
            className="spec_input mb_phone"
            type="text"
            placeholder={verifyMemberData?.mb_phone ?? "raqam kiritilmagan"}
            name="mb_phone"
            onChange={changeMemberPhoneHandler}
          />
        </div>

        <div className="short_input long_input">
          <label className="spec_label">Address</label>
          <input
            className="spec_input mb_address"
            type="text"
            placeholder={verifyMemberData?.mb_address ?? "manzil kiritilmagan"}
            name="mb_address"
            onChange={changeMemberAddressHandler}
          />
        </div>
      </Box>

      <Box className="input_frame">
        <div className="long_input">
          <label className="spec_label">Description</label>
          <textarea
            placeholder={
              verifyMemberData?.mb_description ?? "mavzu kiritilmagan"
            }
            name="mb_description"
            className="spec_textarea mb_description"
            onChange={changeMemberDescriptionHandler}
          />
        </div>
      </Box>

      <Box display="flex" justifyContent="flex-end" sx={{ mt: "25px" }}>
        <Button variant="contained" onClick={handleSubmitBtn}>
          Save
        </Button>
      </Box>
    </Stack>
  );
}
