import axios from "axios";
import assert from "assert";
import { serverApi } from "../../lib/consfig";
import { ProductSearchObj } from "../types/others";
import { Definer } from "../../lib/Definer";
import { Product } from "../types/products";
import { Comment } from "../types/comment";

class ProductApiService {
  setChosenMemberProduct(productsData: ProductSearchObj) {
    throw new Error("Method not implemented.");
  }
  private readonly path: string;
  constructor() {
    this.path = serverApi;
  }

  async getTargetProducts(data: ProductSearchObj) {
    try {
      const url = "/products";
      const result = await axios.post(this.path + url, data, {
        withCredentials: true,
      });
      assert.ok(result, Definer.general_err1);

      console.log("state", result.data.state);

      const products: Product[] = result.data.data;
      return products;
    } catch (err: any) {
      console.log(`ERROR::: getTargetProducts ${err.message}`);
      throw err;
    }
  }
  async getTargetProductsUser(data: ProductSearchObj) {
    try {
      const url = "/products/user";
      const result = await axios.post(this.path + url, data, {
        withCredentials: true,
      });
      assert.ok(result, Definer.general_err1);

      console.log("state", result.data.state);

      const products: Product[] = result.data.data;
      return products;
    } catch (err: any) {
      console.log(`ERROR::: getTargetProducts ${err.message}`);
      throw err;
    }
  }
  async getUserProducts(data: ProductSearchObj) {
    try {
      const url = `/products/users?user_mb_id=${data.user_mb_id}&page=${data.page}&limit=${data.limit}`;
      const result = await axios.post(this.path + url, data, {
        withCredentials: true,
      });
      assert.ok(result, Definer.general_err1);

      console.log("state", result.data.state);

      const products: Product[] = result.data.data;
      return products;
    } catch (err: any) {
      console.log(`ERROR::: getTargetProducts ${err.message}`);
      throw err;
    }
  }
  async getTargetProductsElectric(data: ProductSearchObj) {
    try {
      const url = "/products/electric";
      const result = await axios.post(this.path + url, data, {
        withCredentials: true,
      });
      assert.ok(result, Definer.general_err1);

      console.log("state", result.data.state);

      const products: Product[] = result.data.data;
      return products;
    } catch (err: any) {
      console.log(`ERROR::: getTargetProducts ${err.message}`);
      throw err;
    }
  }

  async getChosenProduct(product_id: string) {
    try {
      const url = `/products/${product_id}`;
      const result = await axios.get(this.path + url, {
        withCredentials: true,
      });
      assert.ok(result?.data, Definer.general_err1);
      assert.ok(result?.data?.state != "fail", result?.data?.message);
      console.log("state:", result.data.state);

      console.log("state:", result.data.state);
      const product: Product = result.data.data;
      return product;
    } catch (err: any) {
      console.log("ERROR:::getChosenDish", err.message);
      throw err;
    }
  }
  async searchProduct(key: any) {
    try {
      console.log(";;;;;", key);
      const url = `/search/${key}`;
      const result = await axios.get(this.path + url, {
        withCredentials: true,
      });
      assert.ok(result?.data, Definer.general_err1);
      assert.ok(result?.data?.state != "fail", result?.data?.message);
      console.log("state:", result.data.state);
      console.log(";;;;;", key);
      console.log("state:", result.data.state);
      const product: Product[] = result.data.data;
      return product;
    } catch (err: any) {
      console.log("ERROR:::searchProduct", err.message);
      throw err;
    }
  }
}

export default ProductApiService;
