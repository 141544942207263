import { createSlice } from "@reduxjs/toolkit";
import { ModelPageState } from "../../types/screen";

const initialState: ModelPageState = {
  products: [],
  chosenProduct: null,
  chosenMember: null,
  comments: null,
  createReply: null,
};

const ModelPageSlice = createSlice({
  name: "modelPage",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setChosenProduct: (state, action) => {
      state.chosenProduct = action.payload;
    },
    setChosenMember: (state, action) => {
      state.chosenMember = action.payload;
    },
    setChosenComment: (state, action) => {
      state.comments = action.payload;
    },
    setChosenCreateReply: (state, action) => {
      state.createReply = action.payload;
    },
  },
});

export const {
  setProducts,
  setChosenProduct,
  setChosenMember,
  setChosenCreateReply,
  setChosenComment,
} = ModelPageSlice.actions;
const ModelPageReducer = ModelPageSlice.reducer;
export default ModelPageReducer;
