import React, { useEffect, useState } from "react";
import "../css/App.css";
import "../css/navbar.css";
import "../css/homepage.css";
import "../css/modelPage.css";
import "../css/footer.css";
import "../css/oilFluid.css";
import "../css/orders.css";
import "../css/community.css";
import "../css/mypage.css";
import "../css/chosen_product.css";
import "../css/service.css";
import "../css/helppage.css";
import "../css/query.css";
import { Box, Container, Stack, Typography } from "@mui/material";

import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import { Switch } from "react-router-dom";
import { MyPage } from "./screens/MyPage";
import { Community } from "./screens/Community";
import { Orders } from "./screens/Orders";
import { OilandFluidsPage } from "./screens/OilandFluidsPage";
import { Services } from "./screens/Services";
import { ElectronicCarsPage } from "./screens/ElectronicCarsPage";
import { ModelPage } from "./screens/ModelPage";
import { NavbarHome } from "./components/header";
import { NavbarOthers } from "./components/header/others";
import { Footer } from "./components/footer";
import { Homepage } from "./screens/HomePage";
import { NavbarOthersElectronic } from "./components/header/othersElectronic";
import AuthenticationModal from "./components/auth";
import { Member } from "./types/user";
import { serverApi } from "../lib/consfig";
import MemberApiService from "./apiServices/memberApiService";
import {
  sweetFailureProvider,
  sweetTopSmallSuccessAlert,
} from "../lib/sweetAlert";
import { Definer } from "../lib/Definer";
import "../app/apiServices/verify";
import { Product } from "./types/products";
import { CartItem } from "./types/others";
import { CommunityChats } from "./components/header/communityChats";
import Up from "./components/header/up";
import { HelpPage } from "./screens/HelpPage";
import { Responsive } from "./screens/Responsive";

function App(props: any) {
  //INITIALIZATION
  const [verifiedMemberData, setVerifiedMemberData] = useState<Member | null>(
    null
  );
  const main_path = window.location.pathname;
  const [path, setPath] = useState();

  const [signUpOpen, setSignUpOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  //logout sign
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const cartJson: any = localStorage.getItem("cart_data");
  const current_cart: CartItem[] = JSON.parse(cartJson) ?? [];
  const [cartItems, setCartItems] = useState<CartItem[]>(current_cart);
  const [orderRebuild, setOrderRebuild] = useState<Date>(new Date());

  useEffect(() => {
    const memberDataJson: any = localStorage.getItem("member_data")
      ? localStorage.getItem("member_data")
      : null;
    const member_data = memberDataJson ? JSON.parse(memberDataJson) : null;
    if (member_data) {
      member_data.mb_image = member_data.mb_image
        ? `${serverApi}/${member_data.mb_image}`
        : "/auth/user.png";
      console.log("===useEffect:APP====");
      console.log("member_data", member_data);
      setVerifiedMemberData(member_data);
    }
  }, [signUpOpen, loginOpen]);

  //HANDLERS
  const handleSignUpOpen = () => setSignUpOpen(true);
  const handleSignUpClose = () => setSignUpOpen(false);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);
  const handleLogOutClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLogOut = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleLogOutRequest = async () => {
    try {
      const memberApiService = new MemberApiService();
      await memberApiService.logOutRequest();
      await sweetTopSmallSuccessAlert("success", 700, true);
    } catch (err: any) {
      console.log(err);
      sweetFailureProvider(Definer.general_err1);
    }
  };
  const onAdd = (product: Product) => {
    const exist: any = cartItems.find(
      (item: CartItem) => item._id === product._id
    );
    if (exist) {
      const cart_updated = cartItems.map((item: CartItem) =>
        item._id === product._id
          ? { ...exist, quantity: exist.quantity + 1 }
          : item
      );
      setCartItems(cart_updated);
      localStorage.setItem("cart_data", JSON.stringify(cart_updated));
    } else {
      const new_item: CartItem = {
        _id: product._id,
        quantity: 1,
        name: product.product_name,
        price: product.product_price,
        image: product.product_images[0],
      };
      const cart_updated = [...cartItems, { ...new_item }];
      setCartItems(cart_updated);
      localStorage.setItem("cart_data", JSON.stringify(cart_updated));
    }
  };
  const onRemove = (item: CartItem) => {
    const item_data: any = cartItems.find(
      (ele: CartItem) => ele._id === item._id
    );

    if (item_data.quantity === 1) {
      const cart_updated = cartItems.filter(
        (ele: CartItem) => ele._id !== item._id
      );

      setCartItems(cart_updated);
      localStorage.setItem("cart_data", JSON.stringify(cart_updated));
    } else {
      const cart_updated = cartItems.map((ele: CartItem) =>
        ele._id === item._id
          ? { ...item_data, quantity: item_data.quantity - 1 }
          : ele
      );
      setCartItems(cart_updated);
      localStorage.setItem("cart_data", JSON.stringify(cart_updated));
    }
  };
  const handleClickOpenAlert = () => {
    history.push("/construction");
  };
  const onDelete = (item: CartItem) => {
    const cart_updated = cartItems.filter(
      (ele: CartItem) => ele._id !== item._id
    );

    setCartItems(cart_updated);
    localStorage.setItem("cart_data", JSON.stringify(cart_updated));
  };
  const onDeleteAll = () => {
    setCartItems([]);
    localStorage.removeItem("cart_data");
  };
  return (
    <Router>
      {main_path == "/model" ? (
        <NavbarOthers
          setPath={setPath}
          handleLoginOpen={handleLoginOpen}
          handleSignUpOpen={handleSignUpOpen}
          verifiedMemberData={verifiedMemberData}
          anchorEl={anchorEl}
          open={open}
          handleLogOutClick={handleLogOutClick}
          handleCloseLogOut={handleCloseLogOut}
          handleLogOutRequest={handleLogOutRequest}
          cartItems={cartItems}
          onAdd={onAdd}
          onRemove={onRemove}
          onDelete={onDelete}
          onDeleteAll={onDeleteAll}
          setOrderRebuild={setOrderRebuild}
        />
      ) : main_path.includes("/electronic-cars") ? (
        <NavbarOthersElectronic
          handleLoginOpen={handleLoginOpen}
          handleSignUpOpen={handleSignUpOpen}
          verifiedMemberData={verifiedMemberData}
          anchorEl={anchorEl}
          open={open}
          handleLogOutClick={handleLogOutClick}
          handleCloseLogOut={handleCloseLogOut}
          handleLogOutRequest={handleLogOutRequest}
          cartItems={cartItems}
          onAdd={onAdd}
          onRemove={onRemove}
          onDelete={onDelete}
          onDeleteAll={onDeleteAll}
          setOrderRebuild={setOrderRebuild}
        />
      ) : (
        <NavbarHome
          setPath={setPath}
          handleLoginOpen={handleLoginOpen}
          handleSignUpOpen={handleSignUpOpen}
          verifiedMemberData={verifiedMemberData}
          anchorEl={anchorEl}
          open={open}
          handleLogOutClick={handleLogOutClick}
          handleCloseLogOut={handleCloseLogOut}
          handleLogOutRequest={handleLogOutRequest}
          cartItems={cartItems}
          onAdd={onAdd}
          onRemove={onRemove}
          onDelete={onDelete}
          onDeleteAll={onDeleteAll}
          setOrderRebuild={setOrderRebuild}
        />
      )}

      <Switch>
        <Route path="/help">
          <HelpPage />
        </Route>
        <Route path="/my-page">
          <MyPage />
        </Route>
        <Route path="/community">
          <Community />
        </Route>
        <Route path="/orders">
          <Orders
            orderRebuild={orderRebuild}
            setOrderRebuild={setOrderRebuild}
          />
        </Route>
        <Route path="/oil-fluids">
          <OilandFluidsPage onAdd={onAdd} />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/electronic-cars">
          <ElectronicCarsPage onAdd={onAdd} />
        </Route>
        <Route path="/model">
          <ModelPage onAdd={onAdd} />
        </Route>
        <Route path="/construction">
          <Responsive
            handleClickOpenAlert={handleClickOpenAlert}
            setPath={setPath}
          />
        </Route>
        <Route path="/">
          <Homepage onAdd={onAdd} />
        </Route>
      </Switch>
      <Footer />

      <AuthenticationModal
        loginOpen={loginOpen}
        handleLoginOpen={handleLoginOpen}
        handleLoginClose={handleLoginClose}
        signUpOpen={signUpOpen}
        handleSignUpOpen={handleSignUpOpen}
        handleSignUpClose={handleSignUpClose}
      />
      <CommunityChats />
      <Up />
    </Router>
  );
}

export default App;
