import { Box, Container, Hidden, Stack } from "@mui/material";
import React, { useState } from "react";

export function Footer() {
  return (
    <div className="footer_config">
      <Container>
        <Stack className="main_footer_container">
          <Stack flexDirection={"row"}>
            <Stack className="info" flexDirection={"column"}>
              <Box className="Logo">AutoParts</Box>
              <Box className="Lorem">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor Sed ut perspiciatis unde omnis iste{" "}
              </Box>
              <Stack className="contact_links">
                <Box>
                  <img src="/icons/face.svg" />
                </Box>
                <Box>
                  <img src="/icons/insta.svg" />
                </Box>
                <Box>
                  <img src="/icons/twit.svg" />
                </Box>
                <Box>
                  <img src="/icons/youtub.svg" />
                </Box>
              </Stack>
            </Stack>
            <Stack className="info2">
              <Box className="bulimlar">Sections</Box>
              <Box className="line2"></Box>
              <Box className="bosh_sahifa">News</Box>
            </Stack>
            <Stack className="info3">
              <Box className="top">Contact</Box>
              <Box className="line"></Box>
              <Stack flexDirection={"row"} sx={{ mt: "19px" }}>
                <Box className="first_line">L.</Box>
                <Box className="second_line">South Korea</Box>
              </Stack>
              <Stack flexDirection={"row"} sx={{ mt: "42px" }}>
                <Box className="first_line">P.</Box>
                <Box className="second_line">+010 - 8381 - 5546</Box>
              </Stack>
              <Stack flexDirection={"row"} sx={{ mt: "9.68px" }}>
                <Box className="first_line">E.</Box>
                <Box className="second_line">islom.khurramov@gmail.com</Box>
              </Stack>
            </Stack>
          </Stack>
          <Box className="liner"></Box>
          <Box className="copyright">
            Copyright AutoParts 2022, All right reserved.
          </Box>
        </Stack>
      </Container>
    </div>
  );
}
