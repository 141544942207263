import { Box, Button, Container, ListItem, Menu, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Logout } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { NavLink, Router } from "react-router-dom";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import { setProducts } from "../../screens/ModelPage/slice";
import { Product } from "../../types/products";
import { retrieveProducts } from "../../screens/ModelPage/selector";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductSearchObj } from "../../types/others";
import ProductApiService from "../../apiServices/productApiService";
import Basket from "./basket";
import { retrieveFinishedOrders } from "../../screens/Orders/selector";
import { Order } from "../../types/order";
import { Products } from "../../screens/ModelPage/products";
import { verifyMemberData } from "../../apiServices/verify";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setProducts: (data: Product[]) => dispach(setProducts(data)),
});
//REDUX SELECTOR
const productsRetriever = createSelector(retrieveProducts, (products) => ({
  products,
}));
//REDUX SELECTOR
const finishedOrdersRetriever = createSelector(
  retrieveFinishedOrders,
  (finishedOrders) => ({
    finishedOrders,
  })
);
export function NavbarOthers(props: any) {
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const { finishedOrders } = useSelector(finishedOrdersRetriever);
  const { setProducts } = actionDispatch(useDispatch());
  const { products } = useSelector(productsRetriever);
  const [searchTerm, setSearchTerm] = useState("");
  let [productsData, setProductsData] = useState<ProductSearchObj>({
    page: 1,
    limit: 5,
    order: "createdAt",
    product_collection_id: "manual transmission",
  });
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  const history = useHistory();

  const handleChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };
  useEffect(() => {
    const productService = new ProductApiService();
    productService
      .getTargetProductsElectric(productsData)
      .then((data) => setProducts(data))
      .catch((err) => console.log(err));
  }, [productRebuild, productsData]);

  useEffect(() => {
    const productSearch = new ProductApiService();
    productSearch
      .searchProduct(searchTerm)
      .then((data) => setProducts(data))
      .catch((err) => console.log(err));
  }, [productsData, productRebuild]);

  //HANDLERS
  const searchCollectionHandler = (collection: string) => {
    productsData.page = 1;
    productsData.product_collection = collection;
    history.push("/model");
    // setProductRebuild({ ...productRebuild });
    setProductsData({ ...productsData });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((val: Product) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return val;
    }
  });

  const myPageHandler = (searchTerm: any) => {
    history.push("/my-page");
  };
  return (
    <div className="home_navbar_container">
      <Container>
        <Stack className="navbar_first_line">
          <Box className="helpcontact">
            <img src="icons/brand2.png" style={{ width: "60px" }} />
          </Box>
          <Box className="login_sign">
            <Box className="helpcontact">
              <span className="flags">
                <img
                  style={{ width: "6%", marginRight: "5px" }}
                  src="/Navbar_icons/english_flag.png"
                />
              </span>
              English
            </Box>
            <Box className="helpcontact" style={{ display: "flex" }}>
              {props.verifiedMemberData ? (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <span style={{ marginRight: "5px" }}>
                    <img src="/Navbar_icons/Vector.png" />
                  </span>
                  <span onClick={myPageHandler}>MyPage</span>
                  <span>
                    <img
                      onClick={props.handleLogOutClick}
                      src={
                        verifyMemberData.mb_image
                          ? verifyMemberData.mb_image
                          : "/auth/user.jpeg"
                      }
                      style={{
                        marginLeft: "10px",
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        border: "solid 1px ",
                      }}
                    />
                  </span>
                </span>
              ) : (
                <span>
                  <span onClick={props.handleLoginOpen}>Login</span>/
                  <span onClick={props.handleSignUpOpen}>Register</span>
                </span>
              )}
              <Menu
                open={props.open}
                onClose={props.handleCloseLogOut}
                onClick={props.handleCloseLogOut}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorEl={props.anchorEl}>
                <MenuItem onClick={props.handleLogOutRequest}>
                  <ListItem>
                    <Logout fontSize="small" style={{ color: "blue" }} />
                  </ListItem>
                  LogOut
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Stack>
        <Stack className="navbar_second_line">
          <Box className="Logo">
            <img src="/homepage/logo.png" style={{ width: "380px" }} />
          </Box>
          <Stack style={{ display: "column" }}>
            <Box style={{ display: "flex", width: "100%" }}>
              <input
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: "100%", height: "2px" }}
                type="text"
              />
            </Box>
            <Box>
              <img
                src="/Navbar_icons/auto-repair-team.png"
                style={{ width: "120%" }}
              />
            </Box>
          </Stack>
          <Stack flexDirection={"row"} marginTop={"40px"}>
            <Box className="helpcontact" marginRight={"20px"}>
              <Basket
                cartItems={props.cartItems}
                onAdd={props.onAdd}
                onRemove={props.onRemove}
                onDelete={props.onDelete}
                onDeleteAll={props.onDeleteAll}
                setOrderRebuild={props.setOrderRebuild}
              />{" "}
            </Box>

            <Stack flexDirection={"column"}>
              {finishedOrders.order ? (
                <Box className="shopping_word">Orders:</Box>
              ) : null}

              {finishedOrders?.map((order: Order) => {
                return (
                  <Box flexDirection={"column"} className="total_price">
                    ${order?.order_total_amount}
                  </Box>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
        <Stack className="navbar_third_line">
          <Box className="shop_category">
            {" "}
            <FormControl fullWidth>
              <Select
                className="category"
                value={selectedCategory}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem
                  onChange={handleChange}
                  style={{ fontFamily: "Play", color: "#a1a1a1" }}
                  value={""}
                  className="category">
                  Shop By Categories
                </MenuItem>
                <MenuItem
                  onClick={() => searchCollectionHandler("body Components")}
                  style={{ fontFamily: "Play" }}
                  value={"body Components"}>
                  Body Components
                </MenuItem>
                <MenuItem
                  onClick={() => searchCollectionHandler("bumpers")}
                  style={{ fontFamily: "Play" }}
                  value={"bumpers"}>
                  Bumpers
                </MenuItem>
                <MenuItem
                  onClick={() => searchCollectionHandler("lighting")}
                  style={{ fontFamily: "Play" }}
                  value={"lighting"}>
                  Lighting
                </MenuItem>
                <MenuItem
                  onClick={() => searchCollectionHandler("hardware")}
                  style={{ fontFamily: "Play" }}
                  value={"hardware"}>
                  Hardware
                </MenuItem>
                <MenuItem
                  onClick={() => searchCollectionHandler("heating")}
                  style={{ fontFamily: "Play" }}
                  value={"heating"}>
                  Heating & Cooling
                </MenuItem>
                <MenuItem
                  onClick={() => searchCollectionHandler("interior")}
                  style={{ fontFamily: "Play" }}
                  value={"interior"}>
                  Interior
                </MenuItem>
                <MenuItem
                  onClick={() => searchCollectionHandler("transmission")}
                  style={{ fontFamily: "Play" }}
                  value={"transmission"}>
                  Transmission
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/home"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Home</a>
              </div>
            </NavLink>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/model"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Products</a>
              </div>
            </NavLink>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/electronic-cars"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Electronic Cars</a>
                <div className="navigation-content">
                  <a style={{ color: "black" }} href="/model">
                    Tesla
                  </a>
                  <a style={{ color: "black" }} href="/model">
                    Hyundai
                  </a>
                  <a style={{ color: "black" }} href="/model">
                    Mersedez-Benz
                  </a>
                  <a style={{ color: "black" }} href="/model">
                    KIA
                  </a>
                  <a style={{ color: "black" }} href="/model">
                    BMW
                  </a>
                  <a style={{ color: "black" }} href="/model">
                    Genesis
                  </a>
                </div>
              </div>
            </NavLink>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/services"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Services</a>
              </div>
            </NavLink>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/oil-fluids"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Oil&Fluids</a>
              </div>
            </NavLink>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/orders"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Orders</a>
              </div>
            </NavLink>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/community"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Community</a>
              </div>
            </NavLink>
          </Box>
          <Box>
            <NavLink
              className={"hover-line"}
              to={"/help"}
              activeClassName="underline"
              onClick={props.setPath}>
              <div className="navigation">
                <a href="#">Contact</a>
              </div>
            </NavLink>
          </Box>
        </Stack>
      </Container>
    </div>
  );
}
