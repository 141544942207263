import { Box } from "@mui/joy";
import {
  Container,
  Stack,
  Button,
  Checkbox,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import { setChosenProduct, setProducts } from "./slice";
import { Product } from "../../types/products";
import { retrieveChosenProduct, retrieveProducts } from "./selector";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductApiService from "../../apiServices/productApiService";
import { ProductSearchObj } from "../../types/others";
import { serverApi } from "../../../lib/consfig";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import assert from "assert";
import MemberApiService from "../../apiServices/memberApiService";
import { Definer } from "../../../lib/Definer";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setProducts: (data: Product[]) => dispach(setProducts(data)),
});
//REDUX SELECTOR
const productsRetriever = createSelector(retrieveProducts, (products) => ({
  products,
}));

export function ProductsElectr(props: any) {
  //INITIALIZATION
  const { setProducts } = actionDispatch(useDispatch());
  const { products } = useSelector(productsRetriever);
  const [searchTerm, setSearchTerm] = useState("");
  let [productsData, setProductsData] = useState<ProductSearchObj>({
    page: 1,
    limit: 3,
    order: "createdAt",
    product_collection_id: "electric",
  });
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  const history = useHistory();

  useEffect(() => {
    const productService = new ProductApiService();
    productService
      .getTargetProductsElectric(productsData)
      .then((data) => setProducts(data))
      .catch((err) => console.log(err));
  }, [productRebuild, productsData]);

  //HANDLERS
  const searchCollectionHandler = (collection: string) => {
    productsData.page = 1;
    productsData.product_collection = collection;
    setProductsData({ ...productsData });
  };

  const targetLikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((val: Product) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.product_collection_model
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      val.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return val;
    }
  });
  const handlePaginationChange = (event: any, value: number) => {
    productsData.page = value;
    setProductsData({ ...productsData });
  };

  return (
    <div className="model_frame">
      <Container className="model_frame">
        <Stack flexDirection={"row"}>
          <Stack className="model_first_frame">
            <Box className="model_intro">Popular Parts</Box>

            <Stack className="first_product_box">
              <Stack
                onClick={() => searchCollectionHandler("body Components")}
                className="hover_product"
                flexDirection={"row"}>
                <Box className="mdoel_first_frame_img">
                  <img src="/electric/elbody.png" />
                </Box>
                <Box className="product_name">Body Components</Box>
              </Stack>
              <Stack
                onClick={() => searchCollectionHandler("bumpers")}
                className="hover_product"
                flexDirection={"row"}>
                <Box className="mdoel_first_frame_img">
                  <img src="/electric/elbum.png" />
                </Box>
                <Box className="product_name">Bumpers</Box>
              </Stack>
              <Stack
                onClick={() => searchCollectionHandler("heating")}
                className="hover_product"
                flexDirection={"row"}>
                <Box className="mdoel_first_frame_img">
                  <img src="/navbarchosen/Heating.png" />
                </Box>
                <Box className="product_name">Heating</Box>
              </Stack>
              <Stack
                onClick={() => searchCollectionHandler("lighting")}
                className="hover_product"
                flexDirection={"row"}>
                <Box className="mdoel_first_frame_img">
                  <img src="/navbarchosen/Lighting.png" />
                </Box>
                <Box className="product_name">Lighting</Box>
              </Stack>
              <Stack
                onClick={() => searchCollectionHandler("hardware")}
                className="hover_product"
                flexDirection={"row"}>
                <Box className="mdoel_first_frame_img">
                  <img src="/electric/elhard.png" />
                </Box>
                <Box className="product_name">Hardware</Box>
              </Stack>
              <Stack
                onClick={() => searchCollectionHandler("interior")}
                className="hover_product"
                flexDirection={"row"}>
                <Box className="mdoel_first_frame_img">
                  <img src="/electric/elinterior.png" />
                </Box>
                <Box className="product_name">Interior</Box>
              </Stack>
              <Stack
                onClick={() => searchCollectionHandler("transmission")}
                className="hover_product"
                flexDirection={"row"}>
                <Box className="mdoel_first_frame_img">
                  <img src="/navbarchosen/Transmission.png" />
                </Box>
                <Box className="product_name">Transmission</Box>
              </Stack>
            </Stack>
          </Stack>

          <Stack className="model_second_frame">
            <Stack style={{ display: "column" }}>
              <Box style={{ display: "flex", width: "120%" }}>
                <input
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    width: "25%",
                    borderRadius: "10px",
                    marginTop: "10px",
                    marginLeft: "50%",
                    height: "37px",
                  }}
                  type="text"
                  placeholder="Searching..."
                />
              </Box>
            </Stack>
            {filteredProducts?.map((product: Product) => {
              const image_path = `${serverApi}/${product?.product_images[0].replace(
                /\\/g,
                "/"
              )}`;
              let lengthComment: any = [];
              let commentLength = product?.product_comments?.length;
              lengthComment.push(commentLength);
              return (
                <Stack className="model_second_box">
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Box className="product_img_box">
                      <img src={image_path} />
                    </Box>
                    <Stack className="product_info_box">
                      <Box className="product_name_second">
                        {product.product_name}
                      </Box>
                      <Box className="product_partnum_second">
                        <span>Part Number</span>
                        :13-65-5A23-987
                      </Box>
                      <Box className="product_desc_second">
                        <span>Description</span>: {product.product_description}
                      </Box>
                      <Box className="product_fits_second">
                        <span>Fits</span>: {product.product_collection_model}
                      </Box>
                    </Stack>
                    <Stack className="price_likes">
                      <Box className="discount">99.9$</Box>
                      <Box className="price">{product.product_price}$</Box>
                      <Button
                        onClick={(e) => {
                          props.onAdd(product);
                          e.stopPropagation();
                        }}
                        className="btn_add"
                        color="secondary"
                        variant="contained">
                        Add to cart
                      </Button>
                      <Stack className="best_likes">
                        <Box className="icon_number">
                          <Checkbox
                            icon={
                              <ThumbUpIcon
                                style={{
                                  color: "#CDCDCD",
                                  border: "black",
                                  width: "15px",
                                }}
                              />
                            }
                            id={product._id}
                            checkedIcon={
                              <ThumbUpIcon
                                style={{ color: "#003A92", width: "15px" }}
                              />
                            }
                            onClick={targetLikeBest}
                            checked={
                              product?.me_liked &&
                              product?.me_liked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Box>
                        <span style={{ fontSize: "12px" }}>
                          {product.product_likes}
                        </span>
                        <Box className="icon_number">
                          <RemoveRedEyeTwoToneIcon
                            style={{ cursor: "pointer", width: "15px" }}
                          />
                        </Box>
                        <span style={{ fontSize: "12px" }}>
                          {product.product_views}
                        </span>
                        <Box className="icon_number">
                          <ModeCommentIcon
                            style={{ cursor: "pointer", width: "15px" }}
                          />
                        </Box>
                        <span style={{ fontSize: "12px" }}>
                          {lengthComment}
                        </span>

                        <Box className="icon_number">
                          <Checkbox
                            id={product._id}
                            icon={
                              <ThumbDownAltIcon
                                style={{
                                  color: "#CDCDCD",
                                  border: "black",
                                  width: "15px",
                                }}
                              />
                            }
                            // id={`${index}`}
                            checkedIcon={
                              <ThumbDownAltIcon
                                style={{ color: "#003A92", width: "15px" }}
                              />
                            }
                            onClick={targetDislikeBest}
                            checked={
                              product?.me_unliked &&
                              product?.me_unliked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Box>
                        <span style={{ fontSize: "12px" }}>
                          {product.product_dislikes}
                        </span>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
            <Box className="bottom_box">
              <Pagination
                count={productsData.page >= 3 ? productsData.page + 1 : 3}
                page={productsData.page}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                    color="secondary"
                  />
                )}
                onChange={handlePaginationChange}
              />
            </Box>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
}
