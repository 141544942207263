import { Container } from "@mui/material";
import React from "react";
import { ProductsElectr } from "./productsElectr";

export function ElectronicCarsPage(props: any) {
  return (
    <div className="model_frame">
      <ProductsElectr onAdd={props.onAdd} />
    </div>
  );
}
