import {
  Box,
  Button,
  Container,
  Tab,
  Pagination,
  PaginationItem,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import { Stack } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import LinkIcon from "@mui/icons-material/Link";
import { MemberPosts } from "./memberPosts";
import { MemberFollowers } from "./memberFollowers";
import { MemberFollowing } from "./memberFollowing";
import { MemberProducts } from "./memberProducts";
import { MySettings } from "./mySettings";
import SettingsIcon from "@mui/icons-material/Settings";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TabList from "@mui/lab/TabList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TuiEditor } from "../../components/tuiEditor/tuiEditor";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TViewer from "../../components/tuiEditor/tuiViewer";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  setChosenMember,
  setChosenMemberBoArticles,
  setChosenMemberProduct,
  setChosenSingleBoArticle,
} from "./slice";
import { Member } from "../../types/user";
import { BoArticle, SearchMemberArticleObj } from "../../types/boArticle";
import {
  retrieveChosenMember,
  retrieveChosenMemberBoArticles,
  retrieveChosenMemberProduct,
  retrieveChosenSingleBoArticle,
} from "./selector";
import { useDispatch, useSelector } from "react-redux";
import { verifyMemberData } from "../../apiServices/verify";
import {
  sweetErrorHandling,
  sweetFailureProvider,
} from "../../../lib/sweetAlert";
import CommunityApiService from "../../apiServices/communityApiService";
import MemberApiService from "../../apiServices/memberApiService";
import { FollowSearchObj } from "../../types/follow";
import ProductApiService from "../../apiServices/productApiService";
import { Product } from "../../types/products";
import { ProductSearchObj } from "../../types/others";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setChosenMember: (data: Member) => dispach(setChosenMember(data)),
  setChosenMemberBoArticles: (data: BoArticle[]) =>
    dispach(setChosenMemberBoArticles(data)),
  setChosenSingleBoArticle: (data: BoArticle) =>
    dispach(setChosenSingleBoArticle(data)),
  setChosenMemberProduct: (data: Product[]) =>
    dispach(setChosenMemberProduct(data)),
});

//REDUX SELECTOR
const chosenMemberRetriever = createSelector(
  retrieveChosenMember,
  (chosenMember) => ({
    chosenMember,
  })
);
const chosenMemberBoArticlesRetriever = createSelector(
  retrieveChosenMemberBoArticles,
  (chosenMemberBoArticles) => ({
    chosenMemberBoArticles,
  })
);
const chosenSingleBoArticleRetriever = createSelector(
  retrieveChosenSingleBoArticle,
  (chosenSingleBoArticle) => ({
    chosenSingleBoArticle,
  })
);
const chosenMemberProductRetriever = createSelector(
  retrieveChosenMemberProduct,
  (chosenMemberProduct) => ({
    chosenMemberProduct,
  })
);

export function VisitMyPage(props: any) {
  const [value, setValue] = useState("1");
  const {
    setChosenMember,
    setChosenMemberBoArticles,
    setChosenSingleBoArticle,
    setChosenMemberProduct,
  } = actionDispatch(useDispatch());

  const { chosenMember } = useSelector(chosenMemberRetriever);
  const { chosenMemberBoArticles } = useSelector(
    chosenMemberBoArticlesRetriever
  );
  const { chosenSingleBoArticle } = useSelector(chosenSingleBoArticleRetriever);
  const { chosenMemberProduct } = useSelector(chosenMemberProductRetriever);
  const [articleRebuild, setArticlesRebuild] = useState<Date>(new Date());
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  const [followRebuild, setFollowRebuild] = useState<boolean>(false);
  const [memberArticleSearchObj, setMemberArticleSearchObj] =
    useState<SearchMemberArticleObj>({ mb_id: "none", page: 1, limit: 2 });
  const [productsData, setProductsData] = useState<ProductSearchObj>({
    user_mb_id: "none",
    page: 1,
    limit: 2,
    // order: "createdAt",
  });
  useEffect(() => {
    if (!verifyMemberData) {
      sweetFailureProvider("please login first", true, true);
    }
    // setChosenMemberBoArticles
    const communityService = new CommunityApiService();
    communityService
      .getMemberCommunityArticles(memberArticleSearchObj)
      .then((data) => setChosenMemberBoArticles(data))
      .catch((err) => console.log(err));

    const memberService = new MemberApiService();
    const productService = new ProductApiService();
    productService
      .getUserProducts(productsData)
      .then((data) => setChosenMemberProduct(data))
      .catch((err) => console.log(err));
    // chosenMemberBoArticles
    //// setChosenMember
    memberService
      .getChosenMember(verifyMemberData?._id)
      .then((data) => setChosenMember(data))
      .catch((err) => console.log(err));
  }, [memberArticleSearchObj, followRebuild, productRebuild, productsData]);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };
  const handlePaginationChange = (event: any, value: number) => {
    memberArticleSearchObj.page = value;
    setMemberArticleSearchObj({ ...memberArticleSearchObj });
  };
  const handlePaginationChangeProduct = (event: any, value: number) => {
    productsData.page = value;
    setProductsData({ ...productsData });
  };
  //renderchosenarticle hadnler
  const renderChosenArticleHandler = async (art_id: string) => {
    try {
      const communityService = new CommunityApiService();
      communityService
        .getChosenArticle(art_id)
        .then((data) => {
          setChosenSingleBoArticle(data);
          setValue("7");
        })
        .catch((err) => console.log(err));
    } catch (err: any) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };
  return (
    <div className="my_page">
      <Container>
        <Stack className="my_page_frame">
          <TabContext value={value}>
            <Stack className="my_page_left">
              <Box display={"flex"} flexDirection={"column"}>
                <TabPanel value="1">
                  <Box className="menu_name">Posts</Box>
                  <MemberPosts
                    chosenMemberBoArticles={chosenMemberBoArticles}
                    renderChosenArticleHandler={renderChosenArticleHandler}
                    setArticlesRebuild={setArticlesRebuild}
                  />

                  <Stack
                    sx={{ my: "40px" }}
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Box className="bottom_box">
                      <Pagination
                        count={
                          memberArticleSearchObj.page >= 3
                            ? memberArticleSearchObj.page + 1
                            : 3
                        }
                        page={memberArticleSearchObj.page}
                        renderItem={(item) => (
                          <PaginationItem
                            components={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                            color="secondary"
                          />
                        )}
                        onChange={handlePaginationChange}
                      />
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel value="2">
                  <Box className="menu_name">Followers</Box>
                  <MemberFollowers
                    actions_enabled={true}
                    mb_id={verifyMemberData?._id}
                    setFollowRebuild={setFollowRebuild}
                    followRebuild={followRebuild}
                  />
                </TabPanel>
                <TabPanel value="3">
                  <Box className="menu_name">Followings</Box>{" "}
                  <MemberFollowing
                    mb_id={verifyMemberData?._id}
                    setFollowRebuild={setFollowRebuild}
                    followRebuild={followRebuild}
                    actions_enabled={true}
                  />
                </TabPanel>
                <TabPanel value={"4"}>
                  <Box className="menu_name">Write a Post</Box>
                  <Box className="write-content">
                    <TuiEditor
                      setValue={setValue}
                      setArticlesRebuild={setArticlesRebuild}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={"7"}>
                  <Box className="menu_name">Chosen Article</Box>
                  <Box className="write-content">
                    <TViewer
                      setArticlesRebuild={setArticlesRebuild}
                      chosen_art_id={props.chosen_art_id}
                      chosenSingleBoArticle={chosenSingleBoArticle}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="5">
                  <Box className="menu_name">Products</Box>{" "}
                  <MemberProducts
                    chosenMemberProduct={chosenMemberProduct}
                    productRebuild={productRebuild}
                    setProductRebuild={setProductRebuild}
                  />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Box>
                      <Pagination
                        count={
                          productsData.page >= 3 ? productsData.page + 1 : 3
                        }
                        page={productsData.page}
                        renderItem={(item) => (
                          <PaginationItem
                            components={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                            color="secondary"
                          />
                        )}
                        onChange={handlePaginationChangeProduct}
                      />
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel value="6">
                  <Box className="menu_name">My Settings</Box>
                  <MySettings />
                </TabPanel>
              </Box>
            </Stack>
            <Stack className="my_page_right">
              <Box className="user_box">
                <a onClick={() => setValue("6")} className="settings_btn">
                  <SettingsIcon color="secondary" />
                </a>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}>
                  <div>
                    <img className="user_icon" src="/users/user1.png" />
                    <img
                      className="user_img"
                      src={verifyMemberData?.mb_image.replace(/\\/g, "/")}
                    />
                  </div>{" "}
                  <span className={"user_name_right"}>
                    {" "}
                    {chosenMember?.mb_nick}
                  </span>
                  <span className={"user_type"}>{chosenMember?.mb_type}</span>
                </Box>
                <Box className="usermedia">
                  <FacebookIcon />
                  <InstagramIcon />
                  <TelegramIcon />
                  <YouTubeIcon />
                </Box>
                <Box className="user_media_box_1">
                  <p className="follows">
                    Followers: {chosenMember?.mb_subscriber_cnt}
                  </p>
                  <p className="follows">
                    Followings: {chosenMember?.mb_follow_cnt}
                  </p>
                </Box>
                <span className="user_desc">
                  {" "}
                  {chosenMember?.mb_description ?? "No Additional information"}
                </span>{" "}
                <Box className="btn_right" sx={{ mt: "20px" }}>
                  <TabList onChange={handleChange}>
                    <Tab
                      value="4"
                      component={(e: any) => (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setValue("4")}>
                          Make a Post
                        </Button>
                      )}
                    />
                  </TabList>
                </Box>
              </Box>
              <Box className="my_page_menu">
                <TabList
                  orientation="vertical"
                  indicatorColor="secondary"
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value={value}
                    component={() => (
                      <div
                        className={`menu_box ${value}`}
                        onClick={() => setValue("1")}>
                        <img src="/icons/Pencil.png" />
                        <span>Posts</span>
                      </div>
                    )}
                  />
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value="2"
                    component={() => (
                      <div
                        className={`menu_box ${value}`}
                        onClick={() => setValue("2")}>
                        <img src={"/icons/User.png"} />
                        <span>Followers</span>
                      </div>
                    )}
                  />
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value="3"
                    component={() => (
                      <div
                        className={`menu_box ${value}`}
                        onClick={() => setValue("3")}>
                        <img src={"/icons/Group.png"} />
                        <span>Following</span>
                      </div>
                    )}
                  />{" "}
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value="5"
                    component={() => (
                      <div
                        style={{ marginBottom: "10px" }}
                        className={`menu_box ${value}`}
                        onClick={() => setValue("5")}>
                        <img
                          className="product_icon"
                          src={"/icons/product.png"}
                        />
                        <span>Products</span>
                      </div>
                    )}
                  />
                </TabList>
              </Box>

              <Link
                className="link_add_product"
                style={{ fontWeight: "bold" }}
                href="http://parthub.org:3006/resto">
                Sell your product with us <KeyboardDoubleArrowRightIcon />
              </Link>
            </Stack>
          </TabContext>
        </Stack>
      </Container>
    </div>
  );
}
