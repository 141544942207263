import {
  Box,
  Container,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Product } from "../../types/products";
import { serverApi } from "../../../lib/consfig";
import { FollowSearchObj } from "../../types/follow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ProductSearchObj } from "../../types/others";
import { useDispatch, useSelector } from "react-redux";
import ProductApiService from "../../apiServices/productApiService";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import { setChosenMemberProduct } from "./slice";
import { retrieveChosenMemberProduct } from "./selector";
import { verifyMemberData } from "../../apiServices/verify";

export function MemberProducts(props: any) {
  const { chosenMemberProduct, setProductRebuild } = props;
  return (
    <Container>
      {chosenMemberProduct?.map((product: Product) => {
        console.log("chosenPRODUCT", props.chosenMemberProduct);
        const image_path = `${serverApi}/${product?.product_images[0].replace(
          /\\/g,
          "/"
        )}`;
        return (
          <Stack className="product_box">
            <img className="product_img" src={image_path} />
            <Stack>
              <Box className="product_name">{product?.product_name}</Box>
              <Box className="product_desc">{product?.product_description}</Box>
            </Stack>
            <Box className="product_price">${product?.product_price}</Box>
          </Stack>
        );
      })}
    </Container>
  );
}
