import { Container } from "@mui/material";
import React from "react";
import { OrdersPage } from "./orders";

export function Orders(props: any) {
  return (
    <div className="orders_frame">
      <OrdersPage
        orderRebuild={props.orderRebuild}
        setOrderRebuild={props.setOrderRebuild}
      />
    </div>
  );
}
