import { createSelector } from "@reduxjs/toolkit";
import { AppRootState } from "../../types/screen";

const selectOilPage = (state: AppRootState) => state.oilAndFluidsPage;
export const retrieveProducts = createSelector(
  selectOilPage,
  (Oilpage) => Oilpage.products
);

export const retrieveChosenMember = createSelector(
  selectOilPage,
  (Oilpage) => Oilpage.chosenMember
);
export const retrieveChosenComment = createSelector(
  selectOilPage,
  (Oilpage) => Oilpage.comments
);
