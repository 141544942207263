import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import HomePageReducer from "./screens/HomePage/slice";
import reduxLogger from "redux-logger";
import ModelPageReducer from "./screens/ModelPage/slice";
import ElectrCarPageReducer from "./screens/ElectronicCarsPage/slice";
import OrdersPageReducer from "./screens/Orders/slice";
import MemberPageReducer from "./screens/MyPage/slice";
import OilandFluidsPageReducer from "./screens/OilandFluidsPage/slice";
import CommunityPageReducer from "./screens/Community/slice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(reduxLogger),
  reducer: {
    homePage: HomePageReducer,
    modelPage: ModelPageReducer,
    electrCarPage: ElectrCarPageReducer,
    ordersPage: OrdersPageReducer,
    memberPage: MemberPageReducer,
    oilAndFluidsPage: OilandFluidsPageReducer,
    communityPage: CommunityPageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
