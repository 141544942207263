import { Container } from "@mui/material";
import React from "react";
import { CommunityPage } from "./communityPage";
import { Box, Link, Stack } from "@mui/material";
import moment from "moment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { serverApi } from "../../../lib/consfig";
import { BoArticle } from "../../types/boArticle";

export function TargetArticles(props: any) {
  const time = moment().format("YY-MM-DD HH:mm");

  return (
    <Container>
      {props.targetBoArticles?.map((article: BoArticle) => {
        const artImage = article?.art_image
          ? `${serverApi}/${article.art_image.replace(/\\/g, "/")}`
          : "/users/default_article.png";
        const userImage = article?.member_data.mb_image
          ? `${serverApi}/${article.member_data.mb_image.replace(/\\/g, "/")}`
          : "/users/user.png";
        return (
          <Link
            className="allarticle_box"
            sx={{ textDecoration: "none" }}
            href={`/my-page/other?mb_id=${article.mb_id}&art_id:${article._id}`}>
            <Box className="allarticle_contanier">
              <Box className="post_img">
                <img src={artImage} />
              </Box>
              <Stack marginLeft={"8px"} flexDirection={"column"} width={"70%"}>
                <Box className="user_img">
                  <img src={userImage} />
                  <p
                    style={{
                      marginLeft: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "black",
                    }}>
                    {" "}
                    {article?.member_data.mb_nick}
                  </p>
                </Box>
                <span
                  className="all_article_title"
                  style={{ marginTop: "5px" }}>
                  {article?.bo_id}
                </span>
                <span className="all_article_desc">{article?.art_subject}</span>
                <div
                  className="time"
                  style={{
                    color: "black",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}>
                  {time}
                </div>
              </Stack>

              <Stack className="likes">
                <Box flexDirection={"row"}>
                  <Checkbox
                    icon={
                      <ThumbUpIcon
                        style={{
                          stroke: "#000",
                          color: "white",
                          fontSize: "13px",
                        }}
                      />
                    }
                    // id={`${index}`}
                    checkedIcon={
                      <ThumbUpIcon
                        style={{
                          stroke: "#000",
                          color: "003A92",
                          fontSize: "13px",
                        }}
                      />
                    }
                    checked={true}
                  />
                  <span style={{ fontSize: "12px" }}>15</span>
                </Box>
                <Box>
                  <Link href="">
                    <ModeCommentIcon style={{ fontSize: "15px" }} />
                  </Link>
                </Box>
                <span style={{ fontSize: "12px" }}>99</span>
                <Box className="product_dislike">
                  <Checkbox
                    icon={
                      <ThumbDownAltIcon
                        style={{
                          stroke: "#000",
                          color: "white",
                          fontSize: "13px",
                        }}
                      />
                    }
                    checkedIcon={
                      <ThumbDownAltIcon
                        style={{
                          stroke: "#000",
                          color: "003A92",
                          fontSize: "13px",
                        }}
                      />
                    }
                    checked={false}
                  />
                  <span style={{ fontSize: "12px" }}>15</span>
                </Box>
              </Stack>
            </Box>
          </Link>
        );
      })}
    </Container>
  );
}
