import { Box, Container, Stack, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import assert from "assert";
import { Definer } from "../../../lib/Definer";
import MemberApiService from "../../apiServices/memberApiService";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { setUsedProducts } from "./slice";
import { retrieveUsedProduct } from "./selector";
import { Product } from "../../types/products";
import ProductApiService from "../../apiServices/productApiService";
import { serverApi } from "../../../lib/consfig";
import { ProductSearchObj } from "../../types/others";
import { useHistory } from "react-router-dom";
import useDeviceDetect from "../../../lib/responsiveDetector";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setUsedProducts: (data: Product[]) => dispach(setUsedProducts(data)),
});
//REDUX SELECTOR
const usedProductsRetriever = createSelector(
  retrieveUsedProduct,
  (usedProducts) => ({
    usedProducts,
  })
);

export function UsedProducts() {
  //INITIALIZATION
  const { setUsedProducts } = actionDispatch(useDispatch());
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  const { usedProducts } = useSelector(usedProductsRetriever);
  const [usedProductsData, newUsedProductData] = useState<ProductSearchObj>({
    page: 1,
    limit: 8,
    order: "createdAt",
    mb_type: "USER",
  });
  const history = useHistory();

  useEffect(() => {
    const productService = new ProductApiService();
    productService
      .getTargetProductsUser(usedProductsData)
      .then((data) => setUsedProducts(data))
      .catch((err) => console.log(err));
  }, [productRebuild]);

  //HANDLER
  const chosenProductHandler = (id: string) => {
    history.push(`/model/product/${id}`);
  };

  const targetLikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };

  const handlePushConstruction = () => {
    window.scrollTo(1000, 0);
    history.push("/construction");
  };
  const { isMobile } = useDeviceDetect();

  if (isMobile()) {
    return (
      <div className="BestSeller used">
        <Container>
          <Box className="best_seller">Used Products</Box>

          <Box className="swiper_best">
            <>
              <Swiper
                /*@ts-ignore*/
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper">
                {usedProducts?.map((product: Product) => {
                  const image_path = `${serverApi}/${product?.product_images[0].replace(
                    /\\/g,
                    "/"
                  )}`;
                  let lengthComment: any = [];
                  let commentLength = product?.product_comments?.length;
                  lengthComment.push(commentLength);
                  return (
                    <SwiperSlide>
                      <Stack className="best_seller_cart">
                        <Box
                          onClick={(e) => handlePushConstruction()}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}>
                          <img
                            style={{
                              backgroundColor: "#FAF0D7",
                            }}
                            src={image_path}
                          />
                        </Box>
                        <Box className="best_info">
                          <span style={{ fontWeight: 700 }}>
                            {product.product_collection_model}
                          </span>
                          -{product.product_name}
                        </Box>
                        <Box className="best_price">
                          {product.product_price}$
                        </Box>
                        <Stack className="best_likes">
                          <Box className="icon_number">
                            <Checkbox
                              icon={
                                <ThumbUpIcon
                                  style={{ color: "#CDCDCD", border: "black" }}
                                />
                              }
                              id={product._id}
                              checkedIcon={
                                <ThumbUpIcon style={{ color: "#003A92" }} />
                              }
                              onClick={targetLikeBest}
                              checked={
                                product?.me_liked &&
                                product?.me_liked[0]?.my_favorite
                                  ? true
                                  : false
                              }
                            />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {product.product_likes}
                          </span>
                          <Box className="icon_number">
                            <RemoveRedEyeTwoToneIcon
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {product.product_views}
                          </span>
                          <Box className="icon_number">
                            <ModeCommentIcon style={{ cursor: "pointer" }} />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {lengthComment}
                          </span>

                          <Box className="icon_number">
                            <Checkbox
                              id={product._id}
                              icon={
                                <ThumbDownAltIcon
                                  style={{ color: "#CDCDCD", border: "black" }}
                                />
                              }
                              // id={`${index}`}
                              checkedIcon={
                                <ThumbDownAltIcon
                                  style={{ color: "#003A92" }}
                                />
                              }
                              onClick={targetDislikeBest}
                              checked={
                                product?.me_unliked &&
                                product?.me_unliked[0]?.my_favorite
                                  ? true
                                  : false
                              }
                            />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {product.product_dislikes}
                          </span>
                        </Stack>
                      </Stack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          </Box>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="BestSeller used">
        <Container>
          <Box className="best_seller">Used Products</Box>

          <Box className="swiper_best">
            <>
              <Swiper
                /*@ts-ignore*/
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper">
                {usedProducts?.map((product: Product) => {
                  const image_path = `${serverApi}/${product?.product_images[0].replace(
                    /\\/g,
                    "/"
                  )}`;
                  let lengthComment: any = [];
                  let commentLength = product?.product_comments?.length;
                  lengthComment.push(commentLength);
                  return (
                    <SwiperSlide>
                      <Stack className="best_seller_cart">
                        <Box
                          onClick={() => chosenProductHandler(product._id)}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}>
                          <img
                            style={{
                              backgroundColor: "#FAF0D7",
                            }}
                            src={image_path}
                          />
                        </Box>
                        <Box className="best_info">
                          <span style={{ fontWeight: 700 }}>
                            {product.product_collection_model}
                          </span>
                          -{product.product_name}
                        </Box>
                        <Box className="best_price">
                          {product.product_price}$
                        </Box>
                        <Stack className="best_likes">
                          <Box className="icon_number">
                            <Checkbox
                              icon={
                                <ThumbUpIcon
                                  style={{ color: "#CDCDCD", border: "black" }}
                                />
                              }
                              id={product._id}
                              checkedIcon={
                                <ThumbUpIcon style={{ color: "#003A92" }} />
                              }
                              onClick={targetLikeBest}
                              checked={
                                product?.me_liked &&
                                product?.me_liked[0]?.my_favorite
                                  ? true
                                  : false
                              }
                            />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {product.product_likes}
                          </span>
                          <Box className="icon_number">
                            <RemoveRedEyeTwoToneIcon
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {product.product_views}
                          </span>
                          <Box className="icon_number">
                            <ModeCommentIcon style={{ cursor: "pointer" }} />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {lengthComment}
                          </span>

                          <Box className="icon_number">
                            <Checkbox
                              id={product._id}
                              icon={
                                <ThumbDownAltIcon
                                  style={{ color: "#CDCDCD", border: "black" }}
                                />
                              }
                              // id={`${index}`}
                              checkedIcon={
                                <ThumbDownAltIcon
                                  style={{ color: "#003A92" }}
                                />
                              }
                              onClick={targetDislikeBest}
                              checked={
                                product?.me_unliked &&
                                product?.me_unliked[0]?.my_favorite
                                  ? true
                                  : false
                              }
                            />
                          </Box>
                          <span style={{ fontSize: "12px" }}>
                            {product.product_dislikes}
                          </span>
                        </Stack>
                      </Stack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          </Box>
        </Container>
      </div>
    );
  }
}
