import { Container, Stack } from "@mui/material";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { PausedOrders } from "./pausedOrders";
import { ProcessOrders } from "./processOrders";
import { FinishedOrders } from "./finishedOrders";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import moment from "moment";
import { Dispatch } from "@reduxjs/toolkit";
import { setFinishedOrders, setPausedOrders, setProcessOrders } from "./slice";
import { Order } from "../../types/order";
import { useDispatch } from "react-redux";
import OrderApiService from "../../apiServices/orderApiService";
import { verifyMemberData } from "../../apiServices/verify";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setPausedOrders: (data: Order[]) => dispach(setPausedOrders(data)),
  setProcessOrders: (data: Order[]) => dispach(setProcessOrders(data)),
  setFinishedOrders: (data: Order[]) => dispach(setFinishedOrders(data)),
});
export function OrdersPage(props: any) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  const [value, setValue] = React.useState("1");

  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setValue(newValue);
  };
  /**INITIALIZATION */
  const { setPausedOrders, setProcessOrders, setFinishedOrders } =
    actionDispatch(useDispatch());

  useEffect(() => {
    const orderApiService = new OrderApiService();
    orderApiService
      .getMyOrders("paused")
      .then((data) => setPausedOrders(data))
      .catch((err) => console.log(err));
    orderApiService
      .getMyOrders("process")
      .then((data) => setProcessOrders(data))
      .catch((err) => console.log(err));
    orderApiService
      .getMyOrders("finished")
      .then((data) => setFinishedOrders(data))
      .catch((err) => console.log(err));
  }, [props.orderRebuild]);

  return (
    <Container className="orders_page">
      <Stack flexDirection={"row"}>
        <Box
          className="tablist_order"
          sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example">
                <Tab label="Orders" value="1" />
                <Tab label="Process" value="2" />
                <Tab label="Finished" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Stack>
                <PausedOrders setOrderRebuild={props?.setOrderRebuild} />
              </Stack>
            </TabPanel>
            <TabPanel value="2">
              <ProcessOrders setOrderRebuild={props?.setOrderRebuild} />
            </TabPanel>
            <TabPanel value="3">
              <FinishedOrders />
            </TabPanel>
          </TabContext>
        </Box>
        <Stack style={{ paddingTop: "130px", width: "30%" }}>
          <Stack className="user_box">
            <Box className="img_box">
              <img src="/users/user1.png" className="user_icon" />
              <img
                className="user_img"
                src={verifyMemberData?.mb_image?.replace(/\\/g, "/")}
              />
            </Box>
            <Box className="user_name">{verifyMemberData?.mb_nick}</Box>
            <Box className="who">{verifyMemberData?.mb_type ?? "User"}</Box>
            <Stack>
              <Box className="line"></Box>
              <Box className="location_user">
                <span>
                  <LocationOnIcon />
                </span>
                {verifyMemberData?.mb_address ?? "No Information"}
              </Box>
            </Stack>
          </Stack>
          <Stack className="card_box">
            <input
              className="card_number"
              type="number"
              placeholder="Card Number:5243 4090 2002 7498"
            />
            <Stack flexDirection={"row"}>
              <input className="card_date" type="number" placeholder="07/24" />
              <input className="card_cvv" type="number" placeholder="CVV:010" />
            </Stack>
            <input
              className="card_name"
              type="name"
              placeholder="Holder Name "
            />
            <Stack className="cards" flexDirection={"row"}>
              <img src="/users/cards.jpg" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
