import { Box, Button, Container, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Badge from "@mui/material/Badge";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ProductApiService from "../../apiServices/productApiService";
import { serverApi } from "../../../lib/consfig";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import assert from "assert";
import { Definer } from "../../../lib/Definer";
import MemberApiService from "../../apiServices/memberApiService";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { setNewProducts } from "./slice";
import { retrieveNewProducts } from "./selector";
import { Product } from "../../types/products";

import { ProductSearchObj } from "../../types/others";
import { useHistory } from "react-router-dom";
import useDeviceDetect from "../../../lib/responsiveDetector";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setNewProducts: (data: Product[]) => dispach(setNewProducts(data)),
});
//REDUX SELECTOR
const newProductsRetriever = createSelector(
  retrieveNewProducts,
  (newProducts) => ({
    newProducts,
  })
);

const product_list = Array.from(Array(8).keys());

export function NewProducts(props: any) {
  //INITIALIZATION
  const { setNewProducts } = actionDispatch(useDispatch());
  const { newProducts } = useSelector(newProductsRetriever);
  let [newProductsData, setNewProductsData] = useState<ProductSearchObj>({
    page: 1,
    limit: 8,
    order: "createdAt",
  });
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  const history = useHistory();

  useEffect(() => {
    const productService = new ProductApiService();
    productService
      .getTargetProducts(newProductsData)
      .then((data) => setNewProducts(data))
      .catch((err) => console.log(err));
  }, [newProductsData, productRebuild]);

  const changeCategoryHandler = (event: any) => {
    const newValue = event.target.value; // The selected sorting option value
    setNewProductsData({ ...newProductsData, order: newValue });
  };
  //HANDLER

  const chosenProductHandler = (id: string) => {
    history.push(`/model/product/${id}`);
  };

  const targetLikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };

  const handlePushConstruction = () => {
    window.scrollTo(1000, 0);
    history.push("/construction");
  };
  const { isMobile } = useDeviceDetect();

  if (isMobile()) {
    return (
      <div>
        <Box className="new_best_seller">New Products</Box>
        <div className="newproducts">
          <Container>
            <Stack className="newproducts_container"></Stack>
            <Box className="dish_wrapper">
              {newProducts?.map((product: Product) => {
                const image_path = `${serverApi}/${product?.product_images[0].replace(
                  /\\/g,
                  "/"
                )}`;
                return (
                  <Box className="dish_box">
                    <Box
                      onClick={(e) => handlePushConstruction()}
                      className="dish_img"
                      sx={{
                        backgroundImage: `url(${image_path})`,
                      }}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className={"like_view_btn"}
                        style={{ left: "36px" }}>
                        <Badge
                          badgeContent={product.product_likes}
                          color="primary">
                          <Checkbox
                            icon={
                              <ThumbUpIcon
                                style={{ color: "#CDCDCD", border: "black" }}
                              />
                            }
                            id={product._id}
                            checkedIcon={
                              <ThumbUpIcon style={{ color: "#003A92" }} />
                            }
                            onClick={targetLikeBest}
                            checked={
                              product?.me_liked &&
                              product?.me_liked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Badge>
                      </Button>
                      <Button
                        onClick={(e) => {
                          props.onAdd(product);
                          e.stopPropagation();
                        }}
                        className="view_btn">
                        <img
                          src="/Navbar_icons/icon_cart.png"
                          style={{ display: "flex" }}
                        />
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="like_view_btn"
                        style={{ marginLeft: "45px" }}>
                        <Badge
                          badgeContent={product.product_dislikes}
                          color="primary">
                          <Checkbox
                            id={product._id}
                            icon={
                              <ThumbDownAltIcon
                                style={{ color: "#CDCDCD", border: "black" }}
                              />
                            }
                            // id={`${index}`}
                            checkedIcon={
                              <ThumbDownAltIcon style={{ color: "#003A92" }} />
                            }
                            onClick={targetDislikeBest}
                            checked={
                              product?.me_unliked &&
                              product?.me_unliked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Badge>
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Box className="new_best_seller">New Products</Box>
        <div className="newproducts">
          <Container>
            <Stack className="newproducts_container"></Stack>
            <Box className="dish_wrapper">
              {newProducts?.map((product: Product) => {
                const image_path = `${serverApi}/${product?.product_images[0].replace(
                  /\\/g,
                  "/"
                )}`;
                return (
                  <Box className="dish_box">
                    <Box
                      onClick={() => chosenProductHandler(product._id)}
                      className="dish_img"
                      sx={{
                        backgroundImage: `url(${image_path})`,
                      }}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className={"like_view_btn"}
                        style={{ left: "36px" }}>
                        <Badge
                          badgeContent={product.product_likes}
                          color="primary">
                          <Checkbox
                            icon={
                              <ThumbUpIcon
                                style={{ color: "#CDCDCD", border: "black" }}
                              />
                            }
                            id={product._id}
                            checkedIcon={
                              <ThumbUpIcon style={{ color: "#003A92" }} />
                            }
                            onClick={targetLikeBest}
                            checked={
                              product?.me_liked &&
                              product?.me_liked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Badge>
                      </Button>
                      <Button
                        onClick={(e) => {
                          props.onAdd(product);
                          e.stopPropagation();
                        }}
                        className="view_btn">
                        <img
                          src="/Navbar_icons/icon_cart.png"
                          style={{ display: "flex" }}
                        />
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="like_view_btn"
                        style={{ marginLeft: "45px" }}>
                        <Badge
                          badgeContent={product.product_dislikes}
                          color="primary">
                          <Checkbox
                            id={product._id}
                            icon={
                              <ThumbDownAltIcon
                                style={{ color: "#CDCDCD", border: "black" }}
                              />
                            }
                            // id={`${index}`}
                            checkedIcon={
                              <ThumbDownAltIcon style={{ color: "#003A92" }} />
                            }
                            onClick={targetDislikeBest}
                            checked={
                              product?.me_unliked &&
                              product?.me_unliked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Badge>
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Container>
        </div>
      </div>
    );
  }
}
