import React from "react";
import { Box, Container, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function ServicesPage() {
  return (
    <Container className="service_page">
      <Box marginLeft={"30px"} marginTop={"60px"}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography className="question">
              What Does a Free Service Include?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Stack flexDirection={"row"}>
                <Stack>
                  <Box className="intro">General Checks</Box>
                  <Box className="desc">
                    The mechanic will take a quick look at some basic systems on
                    your vehicle. For starters, the battery might be checked to
                    ensure it is still holding a charge. All of the exterior
                    lights, the horn and windshield wipers are also inspected.
                    Inside the car, there are also a few general checks taking
                    place. Seat belts can be inspected and the cabin air filter
                    might be replaced.
                  </Box>
                </Stack>
                <Box className="ser_img">
                  <img src="/servicepage/check1.png" />
                </Box>
              </Stack>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Typography className="question">
              What Does a Full Service Include?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Stack flexDirection={"row"}>
                <Box className="ser_img">
                  <img src="/servicepage/enginecare.jpg" />
                </Box>
                <Stack style={{ marginLeft: "40px" }}>
                  <Box className="intro">Engine Care</Box>
                  <Box className="desc">
                    The engine care may be the most important part of the
                    service. The standard oil and filter change will be
                    provided, along with an air filter replacement. All of the
                    drive belts and hoses will also be inspected to ensure there
                    are no underlying issues. Depending on how many miles are on
                    the vehicle, some other services could also be provided. It
                    might be time for spark plug replacement. It could also be
                    time to replace the serpentine belt, which can be done
                    preventatively to avoid larger issues.
                  </Box>
                </Stack>
              </Stack>
              <Stack flexDirection={"row"}>
                <Stack>
                  <Box className="intro">Fluid Checks</Box>
                  <Box className="desc">
                    All of the fluid in the vehicle needs to be checked for
                    level and condition. Among these fluids, you can expect the
                    mechanic to look at the coolant and transmission fluid on
                    top of the oil change. If these are low, the system has to
                    be checked for leaks. Additionally, if the fluid is
                    contaminated, it might be time for a flush or replacement.
                    These may be the major fluids that are checked, but there
                    are others that are also looked at. For example, the
                    mechanic will look at the power steering fluid, brake fluid
                    and windshield washer fluid.
                  </Box>
                </Stack>
                <Box className="ser_img">
                  <img src="/servicepage/fluidcheck.jpg" />
                </Box>
              </Stack>
              <Stack flexDirection={"row"}>
                <Box className="ser_img">
                  <img src="/servicepage/enginecare.jpg" />
                </Box>
                <Stack style={{ marginLeft: "40px" }}>
                  <Box className="intro">Brake System Inspection</Box>
                  <Box className="desc">
                    On top of checking the brake fluid, it’s also important that
                    the entire system gets inspected. A qualified mechanic
                    should evaluate the condition of the brake pads and rotors.
                    If they are worn, now would be the time to replace them. All
                    of the brake hoses and lines should also be inspected for
                    signs of wear. Plus, the brake calipers will be looked at
                    and the emergency brake should be inspected.
                  </Box>
                </Stack>
              </Stack>
              <Stack flexDirection={"row"}>
                <Stack>
                  <Box className="intro">Wheel/Tire Inspection</Box>
                  <Box className="desc">
                    In addition to the brakes, the wheels and tires should
                    always be inspected. First, the tire pressure will be
                    evaluated and changed as needed. Beyond this, the tread
                    depth and condition of the tires gets inspected. If there’s
                    unusual wear, a tire rotation or wheel alignment might be
                    necessary. However, there could be times when the damage is
                    too great and tire replacement is the only option. It’s best
                    to know this ahead of time before a blowout occurs.
                  </Box>
                </Stack>
                <Box className="ser_img">
                  <img src="/servicepage/wheel.jpg" />
                </Box>
              </Stack>
              <Stack flexDirection={"row"}>
                <Box className="ser_img">
                  <img src="/servicepage/Drivetrain.jpg" />
                </Box>
                <Stack style={{ marginLeft: "40px" }}>
                  <Box className="intro">Drivetrain Check</Box>
                  <Box className="desc">
                    We’ve already discussed how important the transmission fluid
                    check is, but that’s not all that’s looked at. The mechanics
                    will also inspect the drivetrain, whether it’s a front-,
                    rear-, all- or four-wheel drive vehicle. The transmission
                    should always shift seamlessly. Plus, the differentials get
                    inspected to ensure that all of the engine power is being
                    sent to the wheels as it should.
                  </Box>
                </Stack>
              </Stack>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <iframe
        style={{
          marginTop: "60px",
        }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25354.108102167193!2d126.65400437431639!3d37.407245999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b70a70c98209b%3A0xa01395767bf1cda8!2sChevrolet%20Southeastern%20Service%20Center!5e0!3m2!1sen!2skr!4v1694208491146!5m2!1sen!2skr"
        width="1320"
        height="500"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </Container>
  );
}
