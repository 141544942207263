import { Box, Button, Container, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetStarted } from "./getStarted";
import { HotSale } from "./hotSale";
import { BestSeller } from "./bestSeller";
import { NewProducts } from "./newProducts";
import { Advertisements } from "./advertisement";
import { UsedProducts } from "./usedProducts";
import { Events } from "./events";
import { Reviews } from "./reviews";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ChosenProduct } from "../ModelPage/chosenProduct";
import { HelpPage } from "../HelpPage";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { setBestSeller } from "./slice";
import { retrieveBestSeller } from "./selector";
import { Product } from "../../types/products";
import ProductApiService from "../../apiServices/productApiService";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import assert from "assert";
import { Definer } from "../../../lib/Definer";
import MemberApiService from "../../apiServices/memberApiService";
import { makeStyles } from "@mui/styles";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setBestSeller: (data: Product[]) => dispach(setBestSeller(data)),
});
//REDUX SELECTOR
const bestSellerRetriever = createSelector(
  retrieveBestSeller,
  (bestSeller) => ({
    bestSeller,
  })
);

export function Homepage(props: any) {
  //INITIALIZATION
  const { setBestSeller } = actionDispatch(useDispatch());
  const { bestSeller } = useSelector(bestSellerRetriever);
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());

  //HANDLERS
  //HANDLER
  const targetLikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeBest = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "product",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };

  let home = useRouteMatch();
  console.log(home);
  return (
    <div className="homepage ">
      <Switch>
        <Route path={`${home.path}/product/:product_id`}>
          <ChosenProduct />
        </Route>
        <Route path={`${home.path}/help`}>
          <HelpPage />
        </Route>
      </Switch>

      <GetStarted />
      <BestSeller
        targetLikeBest={targetLikeBest}
        targetDislikeBest={targetDislikeBest}
      />
      <NewProducts onAdd={props.onAdd} />
      <UsedProducts />
      <Advertisements />
      <Events />
      <Reviews />
      <HotSale />
    </div>
  );
}
function setProductRebuild(arg0: Date) {
  throw new Error("Function not implemented.");
}
