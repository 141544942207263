import {
  Container,
  Stack,
  Link,
  Box,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CommunityPage } from "./communityPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import { BoArticle, SearchArticlesObj } from "../../types/boArticle";
import { setTargetBoArticles } from "./slice";
import { retrieveTargetBoArticles } from "./selector";
import { useDispatch, useSelector } from "react-redux";
import CommunityApiService from "../../apiServices/communityApiService";
//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setTargetBoArticles: (data: BoArticle[]) =>
    dispach(setTargetBoArticles(data)),
});

//REDUX SELECTOR
const targetBoArticlesRetriever = createSelector(
  retrieveTargetBoArticles,
  (targetBoArticles) => ({
    targetBoArticles,
  })
);
export function Community(props: any) {
  //INITALIZATION
  const { setTargetBoArticles } = actionDispatch(useDispatch());
  const { targetBoArticles } = useSelector(targetBoArticlesRetriever);

  const [value, setValue] = React.useState("1");
  const [searchArticlesObj, setSearchArticlesObj] = useState<SearchArticlesObj>(
    {
      bo_id: "all",
      page: 1,
      limit: 5,
    }
  );
  const [articlesRebuild, setArticlesRebuild] = useState<Date>(new Date());

  useEffect(() => {
    const communityService = new CommunityApiService();
    communityService
      .getTargetArticles(searchArticlesObj)
      .then((data) => setTargetBoArticles(data))
      .catch((err) => console.log(err));
  }, [searchArticlesObj, articlesRebuild]);
  //HANDLES
  const handleChange = (event: any, newValue: string) => {
    searchArticlesObj.page = 1;
    switch (newValue) {
      case "1":
        searchArticlesObj.bo_id = "all";
        break;
      case "2":
        searchArticlesObj.bo_id = "celebrity";
        break;
      case "3":
        searchArticlesObj.bo_id = "review";
        break;
      case "4":
        searchArticlesObj.bo_id = "story";
        break;
    }
    setSearchArticlesObj({ ...searchArticlesObj });
    setValue(newValue);
  };

  const handlePaginationChange = (event: any, value: number) => {
    searchArticlesObj.page = value;
    setSearchArticlesObj({ ...searchArticlesObj });
  };
  return (
    <div className="community_frame">
      <CommunityPage
        targetBoArticles={targetBoArticles}
        setArticlesRebuild={setArticlesRebuild}
        handleChange={handleChange}
        value={value}
      />{" "}
      <Stack
        sx={{ width: "75%", height: "60px" }}
        direction="row"
        alignItems="center"
        justifyContent="center">
        <Box className="bottom_box">
          <Pagination
            count={searchArticlesObj.page >= 3 ? searchArticlesObj.page + 1 : 3}
            page={searchArticlesObj.page}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
                color="secondary"
              />
            )}
            onChange={handlePaginationChange}></Pagination>
        </Box>
      </Stack>
    </div>
  );
}
