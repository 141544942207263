import { Container } from "@mui/system";
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Tab,
} from "@mui/material";

export function HelpPage() {
  return (
    <Container className="help_page">
      <Stack
        flexDirection={"row"}
        justifyContent={"center"}
        marginBottom={"120px"}
        marginTop={"142px"}>
        <Box className="main_box">
          <img src="/icons/phone.png" style={{ width: "80px" }} />
          <p className="phone_number">010-8381-5546</p>
          <p className="box_text_desc">accompanied by English versions</p>
        </Box>
        <Box className="main_box">
          <img src="/icons/location.png" style={{ width: "80px" }} />
          <p className="phone_number">Incheon Yeonsu-Dong 8</p>
          <p className="box_text_desc">Our office located in this place</p>
        </Box>
        <Box className="main_box">
          <img src="/icons/mail.png" style={{ width: "80px" }} />
          <p className="phone_number">islom.khurramov@gmail.com</p>
          <p className="box_text_desc">24/7 service is available</p>
        </Box>
      </Stack>
      <Stack className="xat_container">
        <Stack>
          <Stack mt={"90px"} ml={"60px"}>
            <Box
              style={{
                color: "#000",
                fontFamily: "Play",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}>
              Leave a message for admin
            </Box>
            <Box
              style={{
                color: "var(--text-color, #4F547B)",
                fontFamily: "Play",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26px",
                marginTop: "20px",
              }}>
              In order to leave message please fill the form below!
            </Box>
          </Stack>

          <Stack mt={"60px"} ml={"60px"}>
            <Stack>
              <Box className="input_header">Name</Box>
              <input className="input" type="text" />
            </Stack>

            <Stack>
              <Box className="input_header">E-mail</Box>
              <input className="input" type="text" />
            </Stack>

            <Stack>
              <Box className="input_header">Message</Box>
              <input className="input_xat" type="text" />
            </Stack>
          </Stack>
        </Stack>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          mr={"124px"}>
          <Button className="btn" variant="contained">
            Send
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}
