import { Box, Button, Container, Stack } from "@mui/material";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "swiper/css/bundle";

// import required modules
import { EffectCube, Pagination } from "swiper/modules";

export function HotSale() {
  return (
    <div className="hotsale">
      <Container>
        <Stack className="sale_container">
          <>
            <Swiper
              /*@ts-ignore*/
              effect={"cube"} //Type assertion here
              grabCursor={true}
              cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }}
              pagination={true}
              modules={[EffectCube, Pagination]}
              className="mySwiper">
              <SwiperSlide>
                <Stack className="first_sale">
                  <Stack marginTop={"48px"} marginLeft={"32px"}>
                    <Box className="intro_sale">
                      HOT <span style={{ color: "black" }}>SALE</span>
                    </Box>
                    <Box className="info_sale">
                      See what's catching people's attention
                    </Box>
                    <Button className="btn">
                      I WANT IT
                      <span>
                        <img
                          src="/hotsale/right.png"
                          style={{
                            width: "12px",
                            height: "12px",
                            marginLeft: "10px",
                            color: "white",
                          }}
                        />
                      </span>
                    </Button>
                  </Stack>
                  <Box>
                    <img
                      className="first_pic"
                      src="/hotsale/hotsale1.png"
                      alt=""
                    />
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack className="second_sale">
                  <Stack marginTop={"20px"} marginLeft={"32px"}>
                    <Box className="intro_sale">Clearance sale</Box>
                    <Box className="info_sale">
                      Do not miss this unbeatable prices with extra{" "}
                      <span style={{ fontSize: "25px" }}>10%OFF</span>
                    </Box>
                    <Button className="btn">
                      I WANT IT
                      <span>
                        <img
                          src="/hotsale/right.png"
                          style={{
                            width: "12px",
                            height: "12px",
                            marginLeft: "10px",
                            color: "white",
                          }}
                        />
                      </span>
                    </Button>
                  </Stack>
                  <Box>
                    <img className="first_pic" src="/hotsale/hotsale2.png" />
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack className="first_sale">
                  <Stack marginTop={"48px"} marginLeft={"32px"}>
                    <Box className="intro_sale">
                      HOT <span style={{ color: "black" }}>SALE</span>
                    </Box>
                    <Box className="info_sale">
                      See what's catching people's attention
                    </Box>
                    <Button className="btn">
                      I WANT IT
                      <span>
                        <img
                          src="/hotsale/right.png"
                          style={{
                            width: "12px",
                            height: "12px",
                            marginLeft: "10px",
                            color: "white",
                          }}
                        />
                      </span>
                    </Button>
                  </Stack>
                  <Box>
                    <img
                      className="first_pic"
                      src="/hotsale/hotsale1.png"
                      alt=""
                    />
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack className="second_sale">
                  <Stack marginTop={"20px"} marginLeft={"32px"}>
                    <Box className="intro_sale">Clearance sale</Box>
                    <Box className="info_sale">
                      Do not miss this unbeatable prices with extra{" "}
                      <span style={{ fontSize: "25px" }}>10%OFF</span>
                    </Box>
                    <Button className="btn">
                      I WANT IT
                      <span>
                        <img
                          src="/hotsale/right.png"
                          style={{
                            width: "12px",
                            height: "12px",
                            marginLeft: "10px",
                            color: "white",
                          }}
                        />
                      </span>
                    </Button>
                  </Stack>
                  <Box>
                    <img className="first_pic" src="/hotsale/hotsale2.png" />
                  </Box>
                </Stack>
              </SwiperSlide>
            </Swiper>
          </>
        </Stack>
      </Container>
    </div>
  );
}
