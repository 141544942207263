import { Container } from "@mui/material";
import React from "react";
import { ServicesPage } from "./services";

export function Services() {
  return (
    <div>
      <ServicesPage />
    </div>
  );
}
