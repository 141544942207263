import { Container, Stack, Button } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import moment from "moment";
import { createSelector } from "@reduxjs/toolkit";
import { retrieveFinishedOrders } from "./selector";
import { useSelector } from "react-redux";
import { Order } from "../../types/order";
import { Product } from "../../types/products";
import { serverApi } from "../../../lib/consfig";

//REDUX SELECTOR
const finishedOrdersRetriever = createSelector(
  retrieveFinishedOrders,
  (finishedOrders) => ({
    finishedOrders,
  })
);

export function FinishedOrders(props: any) {
  /**INITIALIZATION */
  const { finishedOrders } = useSelector(finishedOrdersRetriever);
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  return (
    <Container className="orders_page">
      {finishedOrders?.map((order: Order) => {
        return (
          <Stack className="order_paused_box">
            <Box className="scroll_box">
              {order?.order_items?.map((item) => {
                const product: Product = order?.product_data.filter(
                  (ele) => ele._id === item?.product_id
                )[0];
                // console.log("******", product);
                const image_path = `${serverApi}/${product?.product_images[0].replace(
                  /\\/g,
                  "/"
                )}`;
                return (
                  <Stack className="order_name_line">
                    <Box className="item_img">
                      <img src={image_path} />
                    </Box>
                    <Box className="item_name">{product?.product_name}</Box>
                    <Stack className="cal_price">
                      <Box>${item?.item_price}</Box>
                      <Box>X</Box>
                      <Box>{item?.item_quantity}</Box>
                      <Box>=</Box>
                      <Box>${item?.item_price * item?.item_quantity}</Box>
                    </Stack>
                  </Stack>
                );
              })}
            </Box>
            <Stack className="total_line_finished">
              <Box className="item_fontstyle_first_finished">Item price:</Box>
              <Box>
                ${order?.order_total_amount - order?.order_delivery_cost}
              </Box>
              <Box>+</Box>
              <Box className="item_fontstyle">Deivery cost:</Box>
              <Box>${order?.order_delivery_cost}</Box>
              <Box>=</Box>
              <Box className="item_fontstyle">Total price:</Box>
              <Box className="item_fontstyle_last_finished">
                ${order?.order_total_amount}
                <span style={{ marginLeft: "10px", fontWeight: "600" }}>
                  {currentDate}
                </span>
                <span style={{ marginLeft: "10px", fontWeight: "600" }}>
                  {currentTime}
                </span>
              </Box>
              <Box></Box>
            </Stack>
          </Stack>
        );
      })}
    </Container>
  );
}
