import {
  Box,
  Container,
  Stack,
  Link,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import assert from "assert";
import { verifyMemberData } from "../../apiServices/verify";
import { Definer } from "../../../lib/Definer";
import MemberApiService from "../../apiServices/memberApiService";
import ReactHtmlParser from "html-react-parser";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import { BoArticle, SearchMemberArticleObj } from "../../types/boArticle";
import { serverApi } from "../../../lib/consfig";
import CommunityApiService from "../../apiServices/communityApiService";

import { Dispatch, createSelector } from "@reduxjs/toolkit";
import {
  retrieveChosenMemberBoArticles,
  retrieveChosenSingleBoArticle,
} from "./selector";
import { useDispatch, useSelector } from "react-redux";
import { setChosenMemberBoArticles } from "./slice";

export function MemberPosts(props: any) {
  const {
    chosenMemberBoArticles,
    renderChosenArticleHandler,
    setArticlesRebuild,
  } = props;
  const time = moment().format("YY-MM-DD HH:mm");

  const targetLikeHandler = async (e: any) => {
    try {
      e.stopPropagation();
      assert.ok(verifyMemberData, Definer.auth_err1);

      const memberService = new MemberApiService();
      const like_result = await memberService.memberLikeTarget({
        like_ref_id: e.target.id,
        group_type: "community",
      });
      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 800, false);
      setArticlesRebuild(new Date());
    } catch (err: any) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeHandler = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "community",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setArticlesRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  return (
    <Container>
      <Stack className="post_content">
        {chosenMemberBoArticles?.map((article: BoArticle) => {
          const image_path = article?.art_image
            ? `${serverApi}/${article?.art_image}`
            : "users/default_article.png";

          const removeEmptyTags = (content: any) => {
            // Remove empty <p> and <br> tags
            const cleanedContent = content
              .replace(/<p[^>]*><\/p>/g, "")
              .replace(/<br[^>]*>/g, "");

            return cleanedContent;
          };

          const removeFirstImage = (content: any) => {
            // Use a regular expression to find and remove the first <img> tag
            const modifiedContent = content.replace(/<img[^>]*>/, "");

            return modifiedContent;
          };

          const truncateText = (text: any, maxLength: any) => {
            const words = text.split(" ");
            if (words.length > maxLength) {
              return words.slice(3, maxLength);
            }
            return text;
          };

          // Remove empty <p> and <br> tags from art_content
          const contentWithoutEmptyTags = removeEmptyTags(article?.art_content);

          const contentWithoutFirstImage = removeFirstImage(
            contentWithoutEmptyTags
          );

          // Truncate the content to the first 100 words
          const truncatedContent = truncateText(contentWithoutFirstImage, 40);

          return (
            <Box
              onClick={() => renderChosenArticleHandler(article?._id)}
              className="allarticle_contanier">
              <Box className="post_img">
                <img src={image_path} />
              </Box>
              <Stack marginLeft={"8px"} flexDirection={"column"} width={"70%"}>
                <Box className="user_img_post">
                  <img
                    src={
                      article?.member_data?.mb_image
                        ? `${serverApi}/${article?.member_data.mb_image}`
                        : "/community/cute_girl.png"
                    }
                  />
                  <p style={{ marginTop: "15px", marginLeft: "10px" }}>
                    {" "}
                    {article?.member_data?.mb_nick}
                  </p>
                </Box>
                <span className="post">{article?.art_subject}</span>
                <span className="post_content_text">
                  {ReactHtmlParser(truncatedContent)}
                </span>
                <div className="time">{time}</div>
              </Stack>

              <Stack
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="likes">
                <Box flexDirection={"row"}>
                  <Checkbox
                    icon={
                      <ThumbUpIcon
                        style={{ color: "#CDCDCD", border: "black" }}
                      />
                    }
                    id={article?._id}
                    checkedIcon={<ThumbUpIcon style={{ color: "#003A92" }} />}
                    onClick={targetLikeHandler}
                    checked={
                      article?.me_liked && article?.me_liked[0]?.my_favorite
                        ? true
                        : false
                    }
                  />
                  <span style={{ fontSize: "12px" }}>{article?.art_likes}</span>
                </Box>
                <Box>
                  <Link href="">
                    <ModeCommentIcon style={{ fontSize: "0.8rem" }} />
                  </Link>
                </Box>
                <span style={{ fontSize: "12px" }}>99</span>
                <Box className="product_dislike">
                  <Checkbox
                    id={article._id}
                    icon={
                      <ThumbDownAltIcon
                        style={{ color: "#CDCDCD", border: "black" }}
                      />
                    }
                    // id={`${index}`}
                    checkedIcon={
                      <ThumbDownAltIcon style={{ color: "#003A92" }} />
                    }
                    onClick={targetDislikeHandler}
                    checked={
                      article?.me_unliked && article?.me_unliked[0]?.my_favorite
                        ? true
                        : false
                    }
                  />
                  <span style={{ fontSize: "12px" }}>
                    {article?.art_dislikes}
                  </span>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </Container>
  );
}
