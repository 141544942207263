import {
  Box,
  Button,
  Container,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { FollowSearchObj, Follower } from "../../types/follow";
import { setMemberFollowers } from "./slice";
import { retrieveMemberFollowers } from "./selector";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FollowApiService from "../../apiServices/followApiService";
import assert from "assert";
import { verifyMemberData } from "../../apiServices/verify";
import { Definer } from "../../../lib/Definer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TuiEditor } from "../../components/tuiEditor/tuiEditor";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import { serverApi } from "../../../lib/consfig";
import { SearchMemberArticleObj } from "../../types/boArticle";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setMemberFollowers: (data: Follower[]) => dispach(setMemberFollowers(data)),
});

//REDUX SELECTOR
const memberFollowersRetriever = createSelector(
  retrieveMemberFollowers,
  (memberFollowers) => ({
    memberFollowers,
  })
);

export function MemberFollowers(props: any) {
  //INITALIZATION
  const history = useHistory();
  const { setFollowRebuild, mb_id, followRebuild } = props;
  const { setMemberFollowers } = actionDispatch(useDispatch());
  const { memberFollowers } = useSelector(memberFollowersRetriever);
  const [followersSearchObj, setFollowersSearchObj] = useState<FollowSearchObj>(
    { page: 1, limit: 5, mb_id: props?.mb_id }
  );

  useEffect(() => {
    const followService = new FollowApiService();
    followService
      .getMemberFollowers(followersSearchObj)
      .then((data) => setMemberFollowers(data))
      .catch((err) => console.log(err));
  }, [followersSearchObj, followRebuild]);
  //setMemberFollowers

  /***HANDLERS */
  const handlePaginationChange = (event: any, value: number) => {
    followersSearchObj.page = value;
    setFollowersSearchObj({ ...followersSearchObj });
  };

  const subscribeHandler = async (e: any, id: string) => {
    try {
      e.stopPropagation();
      assert.ok(verifyMemberData, Definer.auth_err1);

      const followService = new FollowApiService();
      await followService.subscribe(id);

      await sweetTopSmallSuccessAlert(`subscribed successfully`, 700, false);
      setFollowRebuild(!followRebuild);
    } catch (err: any) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };

  const visitMemberHandler = (mb_id: string) => {
    history.push(`/my-page/other?mb_id=${mb_id}`);
    document.location.reload();
  };
  return (
    <Container style={{ maxHeight: "420px" }}>
      {memberFollowers?.map((follower: Follower) => {
        const image_url = follower?.subscriber_member_data?.mb_image
          ? `${serverApi}/${follower?.subscriber_member_data?.mb_image}`
          : "/users/17jk9a.jpg";
        return (
          <Stack
            onClick={() => visitMemberHandler(follower?.subscriber_id)}
            flexDirection={"row"}
            className="follower_box">
            <Box className="user_img_follower">
              <img className="user_img_follower" src={image_url} />
            </Box>

            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}>
              <Stack marginLeft={"15px"}>
                <Box className="user_mail">
                  {" "}
                  {follower?.subscriber_member_data?.mb_type}
                </Box>
                <Box className="user_name">
                  {follower?.subscriber_member_data?.mb_nick}
                </Box>
              </Stack>
              {props.actions_enabled &&
                (follower?.me_followed &&
                follower.me_followed[0]?.my_following ? (
                  <Button
                    variant="contained"
                    className="following_already"
                    disabled>
                    FOLLOWING
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={
                      <img
                        src="/icons/follow_icon.svg"
                        style={{ width: "20px" }}
                      />
                    }
                    onClick={(e) =>
                      subscribeHandler(e, follower?.subscriber_id)
                    }
                    className="follow_btn">
                    Follow Back
                  </Button>
                ))}
            </Stack>
          </Stack>
        );
      })}{" "}
      <Stack
        marginTop={"15px"}
        direction="row"
        alignItems="center"
        justifyContent="center">
        <Box>
          <Pagination
            count={
              followersSearchObj.page >= 3 ? followersSearchObj.page + 1 : 3
            }
            page={followersSearchObj.page}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
                color="secondary"
              />
            )}
            onChange={handlePaginationChange}
          />
        </Box>
      </Stack>
    </Container>
  );
}
