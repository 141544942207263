import { createSlice } from "@reduxjs/toolkit";
import { HomePageState } from "../../types/screen";

const initialState: HomePageState = {
  hotSale: [],
  bestSeller: [],
  newProducts: [],
  usedProducts: [],
  events: [],
  reviews: [],
};

const HomePageSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    setHotSale: (state, action) => {
      state.hotSale = action.payload;
    },
    setBestSeller: (state, action) => {
      state.bestSeller = action.payload;
    },
    setNewProducts: (state, action) => {
      state.newProducts = action.payload;
    },
    setUsedProducts: (state, action) => {
      state.usedProducts = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
  },
});

export const {
  setHotSale,
  setBestSeller,
  setNewProducts,
  setUsedProducts,
  setEvents,
  setReviews,
} = HomePageSlice.actions;

const HomePageReducer = HomePageSlice.reducer;
export default HomePageReducer;
