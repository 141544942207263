import axios from "axios";
import assert from "assert";
import { serverApi } from "../../lib/consfig";
import { ProductSearchObj } from "../types/others";
import { Definer } from "../../lib/Definer";
import { Product } from "../types/products";
import { Comment, ReplyContent } from "../types/comment";

class CommentApiService {
  private readonly path: string;
  constructor() {
    this.path = serverApi;
  }

  async createComment(data: any) {
    try {
      const url = `/comment`;
      const result = await axios.post(this.path + url, data, {
        withCredentials: true,
      });
      assert.ok(result?.data, Definer.general_err1);
      assert.ok(result?.data?.state != "fail", result?.data?.message);
      console.log("state:", result.data.state);

      console.log("state:", result.data.state);
      const comment: Comment = result.data.data;
      return comment;
    } catch (err: any) {
      console.log("ERROR:::getChosenDish", err.message);
      throw err;
    }
  }

  async getComment(art_id: string) {
    try {
      const url = `/comment/myComments/${art_id}`;
      const result = await axios.post(this.path + url, {
        withCredentials: true,
      });
      assert.ok(result?.data, Definer.general_err1);
      assert.ok(result?.data?.state != "fail", result?.data?.message);
      console.log("state:", result.data.state);

      console.log("state:", result.data.state);
      const comment: Comment[] = result.data.data;
      return comment;
    } catch (err: any) {
      console.log("ERROR:::getChosenDish", err.message);
      throw err;
    }
  }

  async createReplyComment(data: any) {
    try {
      const url = `/comment/reply`;
      const result = await axios.post(this.path + url, data, {
        withCredentials: true,
      });
      console.log("commentId:", data);
      assert.ok(result?.data, Definer.general_err1);
      assert.ok(result?.data?.state != "fail", result?.data?.message);

      console.log("state:", result.data.state);
      const comment: ReplyContent[] = result.data.data;
      return comment;
    } catch (err: any) {
      console.log("ERROR:::getChosenDish", err.message);
      throw err;
    }
  }
}

export default CommentApiService;
