import { createSlice } from "@reduxjs/toolkit";
import { OilandFluidsPage } from "../../types/screen";

const initialState: OilandFluidsPage = {
  products: [],
  chosenMember: null,
  comments: null,
};

const ModelPageSlice = createSlice({
  name: "modelPage",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },

    setChosenMember: (state, action) => {
      state.chosenMember = action.payload;
    },
    setChosenComment: (state, action) => {
      state.comments = action.payload;
    },
  },
});

export const { setProducts, setChosenMember, setChosenComment } =
  ModelPageSlice.actions;
const OilandFluidsPageReducer = ModelPageSlice.reducer;
export default OilandFluidsPageReducer;
