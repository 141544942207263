import React, { useEffect, useRef, useState } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Viewer } from "@toast-ui/react-editor";
import { Avatar, Box, Button, Stack } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Checkbox from "@mui/material/Checkbox";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import { Comment, ReplyContent } from "../../types/comment";
import { serverApi } from "../../../lib/consfig";
import assert from "assert";
import CommentApiService from "../../apiServices/commentApiService";
import { Definer } from "../../../lib/Definer";
import { Dispatch, createSelector } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
import { verifyMemberData } from "../../apiServices/verify";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import moment from "moment";
import MemberApiService from "../../apiServices/memberApiService";
import {
  setChosenComment,
  setChosenCreateReply,
} from "../../screens/MyPage/slice";
import { retrieveChosenComment } from "../../screens/MyPage/selector";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setChosenComment: (data: Comment[]) => dispach(setChosenComment(data)),
  setChosenCreateReply: (data: ReplyContent[]) =>
    dispach(setChosenCreateReply(data)),
});

const chosenCommentRetriever = createSelector(
  retrieveChosenComment,
  (comments) => ({
    comments,
  })
);
const replyContentRetriever = createSelector(
  retrieveChosenComment,
  (createReply) => ({
    createReply,
  })
);
const TViewer = (props: any) => {
  const editorRef = useRef();
  const { setChosenComment } = actionDispatch(useDispatch());
  const { comments } = useSelector(chosenCommentRetriever);
  const [commentRebuild, setCommentRebuild] = useState<Date>(new Date());
  const [commentTerm, setCommentTerm] = useState("");
  const [replyCommentTerm, setReplyCommentTerm] = useState("");
  const [replyingToCommentId, setReplyingToCommentId] = useState<string | null>(
    null
  );
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());

  const [toggle, setToggle] = useState(true);

  const dishRelatedProcess = async () => {
    try {
      console.log("art_id", props.chosenSingleBoArticle?._id);
      const commentService = new CommentApiService();
      const comments: Comment[] = await commentService.getComment(
        props.chosenSingleBoArticle?._id
      );
      // console.log("******", product);
      setChosenComment(comments);
    } catch (err) {
      console.log("dishRelatedProcess", err);
    }
  };

  useEffect(() => {
    dishRelatedProcess().then();
  }, [props.setArticlesRebuild, productRebuild, commentRebuild]);

  const handleGetComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentTerm(event.target.value);
  };

  const handleGetReplyComment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReplyCommentTerm(event.target.value);
  };

  const createComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const commentService = new CommentApiService();
      const createComment: any = await commentService.createComment({
        comment_content: commentTerm,
        mb_id: verifyMemberData._id,
        art_id: props.chosenSingleBoArticle?._id,
      });
      assert.ok(createComment, Definer.general_err1);

      await sweetTopSmallSuccessAlert("success", 700, false);
      setCommentRebuild(new Date());
      setCommentTerm(e.target.value);
      props.setArticlesRebuild(new Date());
    } catch (err) {
      console.log("createComment ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const createReplyComment = async (e: any, commentId: string) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);
      console.log("commentIdddddddddd:", commentId);

      const commentService = new CommentApiService();
      const createReplyData: ReplyContent[] =
        await commentService.createReplyComment({
          parent_comment_id: commentId,
          reply_content: replyCommentTerm,
          mb_id: verifyMemberData._id,
        });
      setChosenCreateReply(createReplyData);
      assert.ok(createReplyData, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setCommentRebuild(new Date());
      setReplyCommentTerm(e.target.value);
      setToggle(!toggle);
    } catch (err) {
      console.log("createComment ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetLikeComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberLikeTarget({
          like_ref_id: e.target.id,
          group_type: "comment",
        });

      assert.ok(like_result, Definer.general_err1);

      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  const targetDislikeComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const memberService = new MemberApiService(),
        like_result: any = await memberService.memberUnLikeTarget({
          dislike_ref_id: e.target.id,
          group_type: "comment",
        });

      assert.ok(like_result, Definer.general_err1);
      await sweetTopSmallSuccessAlert("success", 700, false);
      setProductRebuild(new Date());
    } catch (err: any) {
      console.log("targetLikeBest ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };

  // Time Handler
  const formatTimeAgo = (createdTime: Date) => {
    const now = moment();
    const createdMoment = moment(createdTime);

    const seconds = now.diff(createdMoment, "seconds");
    if (seconds < 60) {
      return `${seconds}s`;
    }

    const minutes = now.diff(createdMoment, "minutes");
    if (minutes < 60) {
      return `${minutes}m`;
    }

    const hours = now.diff(createdMoment, "hours");
    if (hours < 24) {
      return `${hours}h`;
    }

    const days = now.diff(createdMoment, "days");
    if (days < 7) {
      return `${days}d`;
    }

    const weeks = now.diff(createdMoment, "weeks");
    if (weeks < 52) {
      return `${weeks}w`;
    }

    const years = now.diff(createdMoment, "years");
    return `${years}y`;
  };

  const handleCommentClick = (commentId: string) => {
    setReplyingToCommentId(commentId);
  };
  let lengthComment = [];
  let commentLength = comments?.length;
  lengthComment.push(commentLength);
  return (
    <Box>
      <Stack
        className={"twiever"}
        sx={{ background: "white", mt: "36px", borderRadius: "10px" }}>
        <Box sx={{ m: "40px" }}>
          <Viewer //@ts-ignore
            ref={editorRef}
            initialValue={props.chosenSingleBoArticle?.art_content}
            height={"600px"}
          />
        </Box>
      </Stack>
      <Box className="comments" fontSize={"26px"} marginTop={"20px"}>
        Comments({lengthComment})
      </Box>
      <Stack className="chat_frame_product">
        <Box className="chat_content_product">
          {comments?.map((comment: Comment) => {
            const formattedTime = formatTimeAgo(comment?.comment?.createdAt);
            const image_path = `${serverApi}/${comment?.comment?.member_data[0]?.mb_image}`;
            console.log("tttt:", comments);
            // Check if this comment has replies
            const hasReplies = comment?.reply_comments?.length ?? 0 > 0;
            // Check if this comment is the one being replied to
            const isReplyingToThisComment = replyingToCommentId === comment._id;

            // console.log("data", image_path);

            return (
              <Stack className="chat_main_product">
                <Box
                  id={comment._id}
                  className="comment_content"
                  flexDirection="row"
                  style={{ display: "flex", alignItems: "center" }}
                  sx={{ m: "10px 0px" }}>
                  <Avatar alt="martin" src={image_path} />

                  <div className="msg_left">
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginBottom: "10px",
                        fontSize: "14px",
                      }}>
                      <div
                        style={{ fontWeight: "bold" }}
                        className="user_names">
                        {comment?.comment?.member_data[0]?.mb_nick}
                        <p className="comment_written_date">
                          {formattedTime} ago
                        </p>
                      </div>
                      <li style={{ listStyleType: "none" }}>
                        {comment?.comment?.comment_content}
                      </li>
                    </div>
                    <Box className="comment_likes_product">
                      <Box className="product_like">
                        <Box flexDirection={"row"}>
                          <span style={{ fontSize: "12px" }}>
                            {comment?.comment?.comment_likes}
                          </span>

                          <Checkbox
                            icon={
                              <ThumbUpIcon
                                style={{
                                  color: "#CDCDCD",
                                  border: "black",
                                  fontSize: "16px",
                                }}
                              />
                            }
                            id={comment._id}
                            checkedIcon={
                              <ThumbUpIcon
                                style={{ fontSize: "16px", color: "#003A92" }}
                              />
                            }
                            onClick={targetLikeComment}
                            checked={
                              comment?.comment?.me_liked &&
                              comment?.comment?.me_liked[0]?.my_favorite
                                ? true
                                : false
                            }
                          />
                        </Box>
                      </Box>
                      <Box className="product_dislike">
                        <span style={{ fontSize: "12px" }}>
                          {comment?.comment?.comment_dislikes}
                        </span>
                        <Checkbox
                          icon={
                            <ThumbDownAltIcon
                              style={{
                                color: "#CDCDCD",
                                border: "black",
                                fontSize: "16px",
                              }}
                            />
                          }
                          id={comment._id}
                          checkedIcon={
                            <ThumbDownAltIcon
                              style={{ fontSize: "16px", color: "#003A92" }}
                            />
                          }
                          onClick={targetDislikeComment}
                          checked={
                            comment?.comment?.me_unliked &&
                            comment?.comment?.me_unliked[0]?.my_favorite
                              ? true
                              : false
                          }
                        />
                      </Box>
                      <Box className="product_reply">
                        <ReplyIcon
                          onClick={() => {
                            setReplyingToCommentId(comment._id);
                            setToggle(!toggle);
                          }}
                          id="replyButton"
                          style={{
                            stroke: "#000",
                            color: "white",
                            fontSize: "1.0rem",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </div>
                </Box>
                {isReplyingToThisComment && !toggle && (
                  <div>
                    <input
                      value={replyCommentTerm}
                      onChange={handleGetReplyComment}
                      className="msg_input"
                      placeholder="Type here ..."
                    />
                    <Button
                      onClick={(e) => {
                        createReplyComment(e, comment._id);
                        // Call the createReplyComment function
                      }} // Pass comment ID to createReplyComment
                      color="secondary"
                      variant="contained"
                      className="send_msg_button">
                      POST
                    </Button>
                  </div>
                )}

                {comment?.reply_comments?.map((replyData: ReplyContent) => {
                  const formattedTim = formatTimeAgo(replyData?.createdAt);
                  const image_path2 = `${serverApi}/${replyData?.member_data[0]?.mb_image}`;
                  console.log("repltData2", replyData);
                  return (
                    <Box
                      className="reply_box"
                      flexDirection="row"
                      style={{ display: "flex", alignItems: "center" }}>
                      {replyData?.member_data[0]?.mb_image ? (
                        <>
                          {" "}
                          <Avatar alt="martin" src={image_path2} />
                        </>
                      ) : null}
                      {replyData?.member_data[0]?.mb_image ? (
                        <>
                          {" "}
                          <div className="msg_left">
                            <div
                              onClick={() => handleCommentClick(comment._id)}
                              style={{
                                marginTop: "10px",
                                marginLeft: "10px",
                                marginBottom: "10px",
                                fontSize: "14px",
                                marginRight: "10px",
                              }}>
                              <div
                                className="user_names"
                                style={{ fontWeight: "bold" }}>
                                {replyData?.member_data[0]?.mb_nick}
                                <p className="comment_written_date">
                                  {formattedTim} ago
                                </p>
                              </div>
                              <li style={{ listStyleType: "none" }}>
                                {replyData?.reply_content}
                              </li>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Box>
                  );
                })}
              </Stack>
            );
          })}
        </Box>
      </Stack>
      <Box className="chat_bott_product">
        <input
          id="myInput"
          value={commentTerm}
          onChange={handleGetComment}
          className="msg_input"
          placeholder="Type here ..."
        />
        <Button
          onClick={createComment}
          color="secondary"
          variant="contained"
          className="send_msg_button">
          POST
        </Button>
      </Box>
    </Box>
  );
};

export default TViewer;
