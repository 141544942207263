import {
  Box,
  Button,
  Container,
  Tab,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import { Stack } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import { MemberPosts } from "./memberPosts";
import { MemberFollowers } from "./memberFollowers";
import { MemberFollowing } from "./memberFollowing";
import { MemberProducts } from "./memberProducts";
import { MySettings } from "./mySettings";
import SettingsIcon from "@mui/icons-material/Settings";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TabList from "@mui/lab/TabList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TViewer from "../../components/tuiEditor/tuiViewer";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import {
  setChosenMember,
  setChosenMemberBoArticles,
  setChosenMemberProduct,
  setChosenSingleBoArticle,
} from "./slice";
import { BoArticle, SearchMemberArticleObj } from "../../types/boArticle";
import { Member } from "../../types/user";
import {
  retrieveChosenMember,
  retrieveChosenMemberBoArticles,
  retrieveChosenMemberProduct,
  retrieveChosenSingleBoArticle,
} from "./selector";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyMemberData } from "../../apiServices/verify";
import CommunityApiService from "../../apiServices/communityApiService";
import MemberApiService from "../../apiServices/memberApiService";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import { Definer } from "../../../lib/Definer";
import assert from "assert";
import FollowApiService from "../../apiServices/followApiService";
import CommentApiService from "../../apiServices/commentApiService";
import { Product } from "../../types/products";
import ProductApiService from "../../apiServices/productApiService";
import { ProductSearchObj } from "../../types/others";

//REDUX SLICE
const actionDispatch = (dispach: Dispatch) => ({
  setChosenMember: (data: Member) => dispach(setChosenMember(data)),
  setChosenMemberBoArticles: (data: BoArticle[]) =>
    dispach(setChosenMemberBoArticles(data)),
  setChosenSingleBoArticle: (data: BoArticle) =>
    dispach(setChosenSingleBoArticle(data)),
  setChosenMemberProduct: (data: Product[]) =>
    dispach(setChosenMemberProduct(data)),
});

//REDUX SELECTOR
const chosenMemberRetriever = createSelector(
  retrieveChosenMember,
  (chosenMember) => ({
    chosenMember,
  })
);
const chosenMemberBoArticlesRetriever = createSelector(
  retrieveChosenMemberBoArticles,
  (chosenMemberBoArticles) => ({
    chosenMemberBoArticles,
  })
);
const chosenSingleBoArticleRetriever = createSelector(
  retrieveChosenSingleBoArticle,
  (chosenSingleBoArticle) => ({
    chosenSingleBoArticle,
  })
);
const chosenMemberProductRetriever = createSelector(
  retrieveChosenMemberProduct,
  (chosenMemberProduct) => ({
    chosenMemberProduct,
  })
);

export function VisitOtherPage(props: any) {
  //INITALIZATION
  const history = useHistory();
  const { chosen_mb_id, chosen_art_id } = props;
  const {
    setChosenMember,
    setChosenMemberBoArticles,
    setChosenSingleBoArticle,
    setChosenMemberProduct,
  } = actionDispatch(useDispatch());
  const { chosenMember } = useSelector(chosenMemberRetriever);
  const { chosenMemberProduct } = useSelector(chosenMemberProductRetriever);
  const { chosenMemberBoArticles } = useSelector(
    chosenMemberBoArticlesRetriever
  );
  const { chosenSingleBoArticle } = useSelector(chosenSingleBoArticleRetriever);
  const [productRebuild, setProductRebuild] = useState<Date>(new Date());
  const [value, setValue] = useState("1");
  const [memberArticleSearchObj, setMemberArticleSearchObj] =
    useState<SearchMemberArticleObj>({
      mb_id: chosen_mb_id,
      page: 1,
      limit: 4,
    });
  const [productsData, setProductsData] = useState<ProductSearchObj>({
    user_mb_id: chosen_mb_id,
    page: 1,
    limit: 3,
    // order: "createdAt",
  });
  const [articleRebuild, setArticlesRebuild] = useState<Date>(new Date());
  const [followRebuild, setFollowRebuild] = useState<boolean>(false);

  useEffect(() => {
    if (chosen_mb_id === verifyMemberData?._id) {
      history.push("/my-page");
    }
    const communityService = new CommunityApiService();
    if (chosen_art_id) {
      communityService
        .getChosenArticle(chosen_art_id)
        .then((data) => {
          setChosenSingleBoArticle(data);
          setValue("4");
        })
        .catch((err) => console.log(err));
    }
    communityService
      .getMemberCommunityArticles(memberArticleSearchObj)
      .then((data) => setChosenMemberBoArticles(data))
      .catch((err) => console.log(err));
    const productService = new ProductApiService();
    productService
      .getUserProducts(productsData)
      .then((data) => setChosenMemberProduct(data))
      .catch((err) => console.log(err));
  }, [
    memberArticleSearchObj,
    productsData,
    productRebuild,
    chosen_mb_id,
    articleRebuild,
  ]);

  useEffect(() => {
    if (chosen_mb_id === verifyMemberData?._id) {
      history.push("/my-page");
    }
    const memberService = new MemberApiService();
    memberService
      .getChosenMember(memberArticleSearchObj.mb_id)
      .then((data) => setChosenMember(data))
      .catch((err) => console.log(err));
  }, [verifyMemberData, chosen_mb_id, followRebuild]);

  const createComment = async (e: any) => {
    try {
      assert.ok(localStorage.getItem("member_data"), Definer.auth_err1);

      const commentService = new CommentApiService();
      const createComment: any = await commentService.createComment({
        comment_content: props.commentTerm,
        mb_id: verifyMemberData._id,
        art_id: chosen_art_id,
      });
      assert.ok(createComment, Definer.general_err1);

      await sweetTopSmallSuccessAlert("success", 700, false);
      props.setCommentRebuild(new Date());
      props.setCommentTerm(e.target.value);
    } catch (err) {
      console.log("createComment ERROR:", err);
      sweetErrorHandling(err).then();
    }
  };
  /***HANDLERS */
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };
  const handlePaginationChange = (event: any, value: number) => {
    memberArticleSearchObj.page = value;
    setMemberArticleSearchObj({ ...memberArticleSearchObj });
  };
  const handlePaginationChangeProduct = (event: any, value: number) => {
    productsData.page = value;
    setProductsData({ ...productsData });
  };
  const renderChosenArticleHandler = async (art_id: string) => {
    try {
      // history.push(`/member-page&art_id=${art_id}`);
      const communityService = new CommunityApiService();
      communityService
        .getChosenArticle(art_id)
        .then((data) => {
          setChosenSingleBoArticle(data);
          setValue("4");
        })
        .catch((err) => console.log(err));
    } catch (err: any) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };

  const subscribeHandler = async (e: any) => {
    try {
      assert.ok(verifyMemberData, Definer.auth_err1);

      const followService = new FollowApiService();
      await followService.subscribe(e.target.value);

      await sweetTopSmallSuccessAlert(`subscribed successfully`, 700, false);
      setFollowRebuild(!followRebuild);
    } catch (err: any) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };

  const unsubscribeHandler = async (e: any) => {
    try {
      assert.ok(verifyMemberData, Definer.auth_err1);

      const followService = new FollowApiService();
      await followService.unsubscribe(e.target.value);

      await sweetTopSmallSuccessAlert(`unsubscribed successfully`, 700, false);
      setFollowRebuild(!followRebuild);
    } catch (err: any) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };

  return (
    <div className="my_page">
      <Container>
        <Stack className="my_page_frame">
          <TabContext value={value}>
            <Stack className="my_page_left">
              <Box display={"flex"} flexDirection={"column"}>
                <TabPanel value="1">
                  <Box className="menu_name">Posts</Box>
                  <MemberPosts
                    chosenMemberBoArticles={chosenMemberBoArticles}
                    renderChosenArticleHandler={renderChosenArticleHandler}
                    setArticlesRebuild={setArticlesRebuild}
                  />{" "}
                  <Stack
                    sx={{ my: "40px" }}
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Box className="bottom_box">
                      <Pagination
                        count={
                          memberArticleSearchObj.page >= 3
                            ? memberArticleSearchObj.page + 1
                            : 3
                        }
                        page={memberArticleSearchObj.page}
                        renderItem={(item) => (
                          <PaginationItem
                            components={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                            color="secondary"
                          />
                        )}
                        onChange={handlePaginationChange}></Pagination>
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel value="2">
                  <Box className="menu_name">Followers</Box>
                  <MemberFollowers
                    actions_enabled={false}
                    mb_id={chosen_mb_id}
                    setFollowRebuild={setFollowRebuild}
                    followRebuild={followRebuild}
                  />
                </TabPanel>
                <TabPanel value="3">
                  <Box className="menu_name">Followings</Box>{" "}
                  <MemberFollowing
                    actions_enabled={false}
                    mb_id={chosen_mb_id}
                    setFollowRebuild={setFollowRebuild}
                    followRebuild={followRebuild}
                  />
                </TabPanel>
                <TabPanel value={"4"}>
                  <Box className="menu_name">Chosen Article</Box>
                  <Box className="write-content">
                    <TViewer
                      chosenSingleBoArticle={chosenSingleBoArticle}
                      chosen_mb_id={props.chosen_mb_id}
                      chosen_art_id={props.chosen_art_id}
                      comments={props.comments}
                      commentTerm={props.commentTerm}
                      replyCommentTerm={props.replyCommentTerm}
                      commentRebuild={props.commentRebuild}
                      replyingToCommentId={props.replyingToCommentId}
                      toggle={props.toggle}
                      handleGetComment={props.handleGetComment}
                      handleGetReplyComment={props.handleGetReplyComment}
                      createComment={createComment}
                      createReplyComment={props.createReplyComment}
                      handleCommentClick={props.handleCommentClick}
                      targetLikeCommen={props.targetLikeComment}
                      targetDislikeComment={props.targetDislikeComment}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="5">
                  <Box className="menu_name">Products</Box>{" "}
                  <MemberProducts
                    setProductRebuild={setProductRebuild}
                    chosenMemberProduct={chosenMemberProduct}
                  />
                  <Stack
                    marginTop={"15px"}
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Box>
                      <Pagination
                        count={
                          productsData.page >= 3 ? productsData.page + 1 : 3
                        }
                        page={productsData.page}
                        renderItem={(item) => (
                          <PaginationItem
                            components={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                            color="secondary"
                          />
                        )}
                        onChange={handlePaginationChangeProduct}
                      />
                    </Box>
                  </Stack>
                </TabPanel>
              </Box>
            </Stack>
            <Stack className="my_page_right">
              <Box className="user_box">
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}>
                  <div>
                    <img className="user_icon" src="/users/user1.png" />
                    <img className="user_img" src="/users/17jk9a.jpg" />
                  </div>{" "}
                  <span className={"user_name_right"}>
                    {chosenMember?.mb_nick}
                  </span>
                  <span className={"user_type"}> {chosenMember?.mb_type}</span>
                </Box>
                <Box className="usermedia">
                  <FacebookIcon />
                  <InstagramIcon />
                  <TelegramIcon />
                  <YouTubeIcon />
                </Box>
                <Box className="user_media_box_1">
                  <p className="follows">
                    Followers: {chosenMember?.mb_subscriber_cnt}
                  </p>
                  <p className="follows">
                    Followings: {chosenMember?.mb_follow_cnt}
                  </p>
                </Box>
                <span className="user_desc">
                  {" "}
                  {chosenMember?.mb_description ?? "No Additional information"}
                </span>{" "}
                <Box className="btn_right" sx={{ mt: "20px" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="Lab API tabs example">
                    {chosenMember?.me_followed &&
                    chosenMember?.me_followed[0]?.my_following ? (
                      <Tab
                        style={{ display: "flex", flexDirection: "column" }}
                        value="4"
                        component={(e: any) => (
                          <Button
                            value={chosenMember?._id}
                            variant="contained"
                            style={{ backgroundColor: "#f70909b8" }}
                            onClick={unsubscribeHandler}>
                            Unfollow
                          </Button>
                        )}
                      />
                    ) : (
                      <Tab
                        style={{ flexDirection: "column" }}
                        value="4"
                        component={(e: any) => (
                          <Button
                            value={chosenMember?._id}
                            variant="contained"
                            style={{ backgroundColor: "#30945e" }}
                            onClick={subscribeHandler}>
                            Follow
                          </Button>
                        )}
                      />
                    )}
                  </TabList>
                </Box>
              </Box>
              <Box className="my_page_menu">
                <TabList
                  orientation="vertical"
                  indicatorColor="secondary"
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value="1"
                    component={() => (
                      <div
                        className={`menu_box ${value}`}
                        onClick={() => setValue("1")}>
                        <img src="/icons/Pencil.png" />
                        <span>Posts</span>
                      </div>
                    )}
                  />
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value="2"
                    component={() => (
                      <div
                        className={`menu_box ${value}`}
                        onClick={() => setValue("2")}>
                        <img src={"/icons/User.png"} />
                        <span>Followers</span>
                      </div>
                    )}
                  />
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value="3"
                    component={() => (
                      <div
                        className={`menu_box ${value}`}
                        onClick={() => setValue("3")}>
                        <img src={"/icons/Group.png"} />
                        <span>Following</span>
                      </div>
                    )}
                  />{" "}
                  <Tab
                    style={{ display: "flex", flexDirection: "column" }}
                    value="5"
                    component={() => (
                      <div
                        style={{ marginBottom: "10px" }}
                        className={`menu_box ${value}`}
                        onClick={() => setValue("5")}>
                        <img
                          className="product_icon"
                          src={"/icons/product.png"}
                        />
                        <span>Products</span>
                      </div>
                    )}
                  />
                </TabList>
              </Box>
            </Stack>
          </TabContext>
        </Stack>
      </Container>
    </div>
  );
}
