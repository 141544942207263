import { Container } from "@mui/material";
import React from "react";
import { OilProducts } from "./oilProducts";
import { Advertisements } from "../HomePage/advertisement";

export function OilandFluidsPage(props: any) {
  return (
    <div className="oil_frame">
      <OilProducts onAdd={props.onAdd} />
      <Advertisements />
    </div>
  );
}
